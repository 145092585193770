import React from 'react';
import PrivateRoute from './PrivateRoute';
//import { AuthContext } from "./Context/Auth";
import Header from './Components/Header/Header';
import Login from './Components/AuthPages/Login';
import Signup from './Components/AuthPages/Signup';
import About from './Components/Include/About';
import Contact from './Components/Include/Contact';
import TermsConditions from './Components/Include/TermsConditions';
import PrivacyPolicy from './Components/Include/PrivacyPolicy';
import SiteMap from './Components/Include/SiteMap';
import SucurSales from './Components/Include/SucurSales';
import Krediya from './Components/Include/Krediya';
import Success from './Components/Include/Success';
import Home from './Components/Home/Home';
import ProductList from './Components/ProductList/ProductList';
import ProductListCategoryWise from './Components/ProductListCategoryWise/ProductList';
import ProductListPromotioanal from './Components/ProductListPromotioanal/ProductList';
import ProductDetails from './Components/ProductView/ProductDetails';
import SearchProductList from './Components/Search/SearchProductList';
import FabricantesProductList from './Components/Fabricantes/FabricantesProductList';
import PromosProductList from './Components/Promos/PromosProductList';
import OnlineSaleProductList from './Components/Onlinesale/OnlineSaleProductList';
import LgSummerFestProductList from './Components/LGSummerFest/LgSummerFestProductList';
import BrandShop from './Components/BrandShop/BrandShopPage';
import Landings from './Components/Landings/LandingBrandShopPage';
import Cart from './Components/Cart/CartPage';
import Checkout from './Components/Checkout/CheckoutPage';
import Quotation from './Components/Quotation/QuotationPage';
import Footer from './Components/Footer/Footer';
import Profile from './Components/AuthPages/Profile';
import MyAccount from './Components/AuthPages/MyAccount';
import MyAddressBook from './Components/AuthPages/MyAddressBook';
import MyAddressEdit from './Components/AuthPages/MyAddressEdit';
import MyOrders from './Components/AuthPages/MyOrders';
import MyOrderDetails from './Components/AuthPages/MyOrderDetails';
import MyQuotations from './Components/AuthPages/MyQuotations';
import ErrorPage from './Components/404Page/Error'
import FooterCopyrightSection from './Components/Footer/FooterCopyrightSection';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App (props) {
    // const existingTokens = JSON.parse(localStorage.getItem("tokens"));
    // const [authTokens, setAuthTokens] = useState(existingTokens);

    // const setTokens = (responseJson) => {
    //     localStorage.setItem("tokens", JSON.stringify(responseJson));
    //     setAuthTokens(responseJson);
    // }
    
    return(
        //<AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
        // <Router basename={'/evreact'}>
        <Router>
            <Header />
            <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/product-category/:categoryId/:brand?" component={ProductList} />
            <Route path="/categorias/:categoryName?/:subCategoryName?/:lastSubCategoryName?" component={ProductListCategoryWise} />
            <Route path="/promo/:promoId?" component={ProductListPromotioanal} />
            <Route path="/product/:productId" component={ProductDetails} />
            <Route path="/producto/:productId" component={ProductDetails} />
            <Route path="/search/:keyword" component={SearchProductList} />
            <Route path="/fabricantes/:keyword" component={FabricantesProductList} />
            <Route path="/promos/:keyword" component={PromosProductList} />
            <Route path="/onlinesale" component={OnlineSaleProductList} />
            <Route path="/lgsummerfest" component={LgSummerFestProductList} />
            <Route path="/brandshop/:brandName?" component={BrandShop} />
            <Route path="/landings/:categoryName?/:brandName?" component={Landings} />
            <Route path="/cart" component={Cart} />
            <PrivateRoute path="/checkout" component={Checkout} />
            <PrivateRoute path="/quotation" component={Quotation} />
            <Route path="/login" component={Login} page="login"/>
            <Route path="/signup" component={Signup} />
            <Route path="/about" component={About} />
            <Route path="/contacto" component={Contact} />
            <Route path="/terminos" component={TermsConditions} />
            <Route path="/politicas" component={PrivacyPolicy} />
            <Route path="/mapa" component={SiteMap} />
            <Route path="/sucursales" component={SucurSales} />
            <Route path="/krediya" component={Krediya} />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/myaccount" component={MyAccount} />
            <PrivateRoute path="/addressbook" component={MyAddressBook} />
            <PrivateRoute path="/addressedit" component={MyAddressEdit} />
            <PrivateRoute path="/orders" component={MyOrders} />
            <PrivateRoute path="/orderview/:orderId" component={MyOrderDetails} />
            <PrivateRoute path="/myquotations" component={MyQuotations} />
            <Route path="/success" component={Success} />
            {/* <Route path="/success/:order_id/payment_method" component={Success} /> */}
            <Route component={ErrorPage} />
            </Switch>
            <Footer />
            <FooterCopyrightSection />
        </Router>
        //</AuthContext.Provider>
    )
}

export default App;