import React, {Component} from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import MobileHeaderTopSection from './MobileHeaderTopSection';
import DesktopHeaderTopComp from './DesktopHeaderTopSection';
import DesktopHeaderMenuSection from './DesktopHeaderMenuSection';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import {isMobile} from 'react-device-detect';
import API_BASE_URL from "../../config/api";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
                currenturl: window.location.pathname,
                matchurl: '/'
        }
    }

    componentDidMount() {
        let path_name = this.props.location.pathname;
        let path_name_arr = path_name.split("/");
        let page_category;
        let product_id;
        let category_id;
        let brand_name;

        // let queryStringVal = queryString.parse(this.props.location.search);
        // let queryStringData = "";
        // if(queryStringVal.brand !== undefined){
        //     queryStringData = queryStringVal.brand;
        // }

        const params = new URLSearchParams(this.props.location.search);
        const queryStringData = params.get('brand');
        //console.log(brandName);

        if(path_name_arr.length > 0){
            page_category = path_name_arr[1];

            if(page_category==="product" || page_category==="producto"){
                product_id = path_name_arr[2];
            } else if(page_category==="product-category"){
                category_id = path_name_arr[2];
            } else if(page_category==="categorias"){
                category_id = path_name_arr.at(-1);
            } else if(page_category==="fabricantes"){
                brand_name = path_name_arr[2];
            } else if(page_category==="brandshop"){
                brand_name = path_name_arr[2];
            } else if(page_category==="search"){
                brand_name = path_name_arr[2];
            }else if(page_category==="promo"){
                brand_name = path_name_arr[2];
            } else if (page_category==="landings"){
                category_id = path_name_arr[2];
                brand_name = path_name_arr[3];
            }
        }else{
            page_category = "home";
        }

        //const apiUrl = 'https://www.evisionstore.com/api/home/titulos.php';
        const apiUrl = `${API_BASE_URL}/page-meta`;
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                "page_category": page_category,
                "product_category_id": category_id,
                "product_id": product_id,
                "brand_name": brand_name,
                "query_string": queryStringData
            })
        };
        fetch(apiUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                //console.log(result)
                this.setState({
                    titular: result.data.titular,
                    metatitle: result.data.metatitle,
                    metadesc: result.data.metadesc,
                    metakeywords: result.data.metakeywords,
                    canonicalUrl: result.data.canonicalUrl,
                    isCategoryLoaded: true
                });
            },
            (error) => {
                this.setState({ error: error });
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.location.pathname !== this.props.location.pathname) {
            
            this.setState({
                isCategoryLoaded: false
            });

            let path_name = this.props.location.pathname;
            let path_name_arr = path_name.split("/");
            let page_category;
            let product_id;
            let category_id;
            let brand_name;

            // let queryStringVal = queryString.parse(this.props.location.search);
            // let queryString = "";
            // if(queryStringVal.brand !== undefined){
            //     queryString = queryStringVal.brand;
            // }

            const params = new URLSearchParams(this.props.location.search);
            const queryStringData = params.get('brand');
            
            if(path_name_arr.length > 0){
                page_category = path_name_arr[1];
    
                if(page_category==="product" || page_category==="producto"){
                    product_id = path_name_arr[2];
                } else if(page_category==="product-category"){
                    category_id = path_name_arr[2];
                } else if(page_category==="categorias"){
                    category_id = path_name_arr.at(-1);
                } else if(page_category==="fabricantes"){
                    brand_name = path_name_arr[2];
                } else if(page_category==="search"){
                    brand_name = path_name_arr[2];
                } else if (page_category==="landings"){
                    category_id = path_name_arr[2];
                    brand_name = path_name_arr[3];
                }
            }else{
                page_category = "home";
            }

            //const apiUrl = 'https://www.evisionstore.com/api/home/titulos.php';
            const apiUrl = `${API_BASE_URL}/page-meta`;
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({
                    "page_category": page_category,
                    "product_category_id": category_id,
                    "product_id": product_id,
                    "brand_name": brand_name,
                    "query_string": queryString
                })
            };
            fetch(apiUrl, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    //console.log(result)
                    this.setState({
                        titular: result.data.titular,
                        metatitle: result.data.metatitle,
                        metadesc: result.data.metadesc,
                        metakeywords: result.data.metakeywords,
                        canonicalUrl: result.data.canonicalUrl,
                        isCategoryLoaded: true
                    });
                },
                (error) => {
                    this.setState({ error: error });
                }
            )
        }
    }

    render(){
        let path_name = this.props.location.pathname;
        //console.log("URL: ", path_name)
        // const CheckExactPath = (props) => {
        //     let currurl = this.state.currenturl;    
        //     let matchurlchck = this.state.matchurl;
    
        //     if(currurl===matchurlchck){
        //         return <MainSlider />
        //     }else{
        //         return "";
        //     }
        // }

        let path_arr = path_name.split("/");
        let page_name;
        
        if(path_arr.length > 0){
            page_name = path_arr[1];

            if(page_name===""){
                return(
                    <>  
                        <Helmet>
                            <title>Compra Electrónica y Línea Blanca en Panamá | E-Vision Panamá</title>    
                            <meta name="description" content="Encuentra las mejores ofertas en televisores, cámaras, audio, computadoras y línea blanca en Panamá. Compra fácil, con garantía y entrega rápida a todo el país." />
                            <meta name="keywords" content="Venta online, compras por internet, compra online, evision, E Vision, evision panama, samsung, lg, sony, panasonic, hisense, huawei, sankey, frigidaire, canon, nikon, tecnología, honor, yamaha" />
                            <meta name="robots" content="index,follow" />
                            <meta name="format-detection" content="telephone=no" />
                        </Helmet>
                        {isMobile ? <MobileHeaderTopSection /> : <DesktopHeaderTopComp />}
                        {/* <DesktopHeaderMenuSection /> */}
                    </>
                )
            } else if(page_name==="producto" || page_name==="fabricantes" || page_name==="brandshop"){
                return(
                    <>  
                        <Helmet>
                            <title>{ this.state.titular }</title>
                            <meta name="title" content={this.state.metatitle} />
                            <meta name="description" content={this.state.metadesc} />
                            <meta name="keywords" content={this.state.metakeywords} />
                            <meta name="robots" content="index,follow" />
                            <meta name="format-detection" content="telephone=no" />
                            <link rel="canonical" href={this.state.canonicalUrl} />
                        </Helmet>
                        {isMobile ? <MobileHeaderTopSection /> : <DesktopHeaderTopComp />}
                        {/* <DesktopHeaderMenuSection /> */}
                    </>
                )
            } else if(page_name === "product") {
                return(
                    <>  
                        <Helmet>
                            <title>{ this.state.titular }</title>
                            <meta name="title" content={this.state.metatitle} />
                            <meta name="description" content={this.state.metadesc} />
                            <meta name="keywords" content={this.state.metakeywords} />
                            <meta name="robots" content="noindex, nofollow"></meta>
                            <meta name="format-detection" content="telephone=no" />
                            <link rel="canonical" href={this.state.canonicalUrl} />
                        </Helmet>
                        {isMobile ? <MobileHeaderTopSection /> : <DesktopHeaderTopComp />}
                    </>
                )
            } else if(page_name === "categorias"){
                return(
                    <>  
                        <Helmet>
                            <title>{ this.state.titular }</title>
                            <meta name="title" content={this.state.metatitle} />
                            <meta name="description" content={this.state.metadesc} />
                            <meta name="keywords" content={this.state.metakeywords} />
                            <meta name="robots" content="index,follow" />
                            <meta name="format-detection" content="telephone=no" />
                            <link rel="canonical" href={this.state.canonicalUrl} />
                        </Helmet>
                        {isMobile ? <MobileHeaderTopSection /> : <DesktopHeaderTopComp />}
                        {/* <DesktopHeaderMenuSection /> */}
                    </>
                )
            } else if(page_name === "product-category"){
                return(
                    <>  
                        <Helmet>
                            <title>{ this.state.titular }</title>
                            <meta name="title" content={this.state.metatitle} />
                            <meta name="description" content={this.state.metadesc} />
                            <meta name="keywords" content={this.state.metakeywords} />
                            <meta name="robots" content="noindex, nofollow" />
                            <meta name="format-detection" content="telephone=no" />
                            <link rel="canonical" href={this.state.canonicalUrl} />
                        </Helmet>
                        {isMobile ? <MobileHeaderTopSection /> : <DesktopHeaderTopComp />}
                        {/* <DesktopHeaderMenuSection /> */}
                    </>
                )
            } else if(page_name==="brandshops") {
                return(
                    <>  
                        <Helmet>
                            <title>E-Vision &raquo; Brandshop: {path_arr[2].toUpperCase()}</title>
                            <meta name="description" content={this.state.metadesc} />
                        </Helmet>
                        {isMobile ? <MobileHeaderTopSection /> : <DesktopHeaderTopComp />}
                        {/* <DesktopHeaderMenuSection /> */}
                    </>
                )
            } else if(page_name==="landings") {
                return(
                    <>  
                        <Helmet>
                            <link rel="canonical" href={this.state.canonicalUrl} />
                        </Helmet>
                        {isMobile ? <MobileHeaderTopSection /> : <DesktopHeaderTopComp />}
                        {/* <DesktopHeaderMenuSection /> */}
                    </>
                )
            } else if(page_name==="search") {
                return(
                    <>  
                        <Helmet>
                            <title>Resultados de Búsqueda para {path_arr[2].toUpperCase()} | E Vision  </title>
                            <meta name="title" content={`Resultados de Búsqueda para ${path_arr[2].toUpperCase()} | E Vision`} />
                            <meta name="description" content="Albrook Mall | Los Pueblos | Online | E Vision te trae la mejor calidad en los productos que necesitas para tu hogar y la oficina. Contamos con una gran variedad de cómputo, línea blanca, televisores y mucho más." />
                            <meta name="keywords" content="e-commerce, electronica, linea-blanca, tecnologia, compra online" />
                            <meta name="robots" content="index,follow" />
                            <meta name="format-detection" content="telephone=no" />
                        </Helmet>
                        {isMobile ? <MobileHeaderTopSection /> : <DesktopHeaderTopComp />}
                        {/* <DesktopHeaderMenuSection /> */}
                    </>
                )
            } else {
                return(
                    <>  
                        <Helmet>
                            <title>{ this.state.titular }</title>
                            <meta name="description" content={this.state.metadesc} />
                        </Helmet>
                        {isMobile ? <MobileHeaderTopSection /> : <DesktopHeaderTopComp />}
                        {/* <DesktopHeaderMenuSection /> */}
                    </>
                )
            } 
        }else{
            return(
                <>  
                    <Helmet>
                        <title>{ this.state.titular }</title>
                        <meta name="description" content={this.state.metadesc} />
                    </Helmet>
                    <DesktopHeaderTopComp />
                    <MobileHeaderTopSection />
                    {/* <DesktopHeaderMenuSection /> */}
                </>
            )
        }

    }
}

export default withRouter(Header);