import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
import API_BASE_URL from '../../config/api';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const TITLE = 'Contáctanos | Atención y Soporte en E-Vision Panamá';

export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            errors: {}, contactDisabled : false,
            contactName: '', contactEmail: '', contactPhone: '', contactMessage: '', 
            submittedContact: false, contactLoader: "hidden", contactApiSuccessResponse: '', contactApiErrorResponse: '' 
        }

        this.handleOnContactChange = this.handleOnContactChange.bind(this);
        this.handleOnContactSubmit = this.handleOnContactSubmit.bind(this);
    }

    handleOnContactChange(e){
        const { name, value } = e.target;
        this.setState({ [name]: value });

        let errors = this.state.errors;
        errors[name] = "";
        this.setState({errors: errors });
    }

    handleConatctFormValidation(){
        let contactName = this.state.contactName;
        let contactEmail = this.state.contactEmail;
        let contactPhone = this.state.contactPhone;
        let contactMessage = this.state.contactMessage;
        let errors = {};
        let contactFormIsValid = true;

        if(!contactName){
           contactFormIsValid = false;
           errors["contactName"] = "por favor, escriba su nombre";
        }
    
        if(typeof contactEmail !== "undefined"){
           let lastAtPos = contactEmail.lastIndexOf('@');
           let lastDotPos = contactEmail.lastIndexOf('.');
    
           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && contactEmail.indexOf('@@') === -1 && lastDotPos > 2 && (contactEmail.length - lastDotPos) > 2)) {
              contactFormIsValid = false;
              errors["contactEmail"] = "El correo no es válido";
            }
        } else{
            if(!contactEmail){
                contactFormIsValid = false;
                errors["contactEmail"] = "El correo electrónico no puede estar vacío";
            }
        }

        if(!contactPhone){
            contactFormIsValid = false;
            errors["contactPhone"] = "Por favor, introduzca su número de teléfono";
        }else{
            if(contactPhone.length < 10){
                contactFormIsValid = false;
                errors["contactPhone"] = "Ingrese su número de teléfono válido";
            }
        }

        if(!contactMessage){
            contactFormIsValid = false;
            errors["contactMessage"] = "El mensaje no puede estar vacío";
         }
    
       this.setState({errors: errors});
       return contactFormIsValid;
    }

    handleOnContactSubmit(e){
        e.preventDefault();
        this.setState({ submittedContact: true, contactApiSuccessResponse: '', contactApiErrorResponse: '' });

        if(this.handleConatctFormValidation()){
            this.setState({ contactLoader: "", contactDisabled: true });
            const contactName = this.state.contactName;
            const contactEmail = this.state.contactEmail;
            const contactPhone = this.state.contactPhone;
            const contactMessage = this.state.contactMessage;

            const apiUrl = `${API_BASE_URL}/contact`;
        
            fetch(apiUrl, {
                method: 'POST',
                body: JSON.stringify({
                    "name": contactName,
                    "email": contactEmail,
                    "telephone": contactPhone,
                    "message": contactMessage
                })
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.code === 200){
                        this.setState({
                            contactApiSuccessResponse: result.message,
                            contactLoader: "hidden",
                            contactDisabled: false,
                            contactName: '', contactEmail: '', contactPhone: '', contactMessage: ''
                        });
                    }else{
                        this.setState({
                            contactApiErrorResponse: result.error,
                            contactLoader: "hidden",
                            contactDisabled: false
                        });
                    }
                },
                (error) => {
                    this.setState({ error, contactDisabled: false });
                }
            )
        }
    }

    render(){
        const { submittedContact, contactName, contactEmail, contactPhone, contactMessage } = this.state;
        return(
            <div className="">
                <Helmet>
                    <title>{ TITLE }</title>
                    <meta name="description" content="¿Necesitas ayuda con tu compra o información sobre nuestros productos? Contáctanos en E-Vision Panamá y recibe asistencia personalizada." />
                </Helmet>
                <section className="banner-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 p-md-0">
                                <div className="banner-area-general banner-area-general--contact">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <h1 className="banner-title">Consúltanos cualquier duda, estamos para ayudarte</h1>
                                            <p>¿Le gustaría hablar con nuestro personal de ventas? Simplemente envíenos su información de contacto y nos comunicaremos con usted lo antes posible. También puedes enviarnos un correo electrónico si prefieres ese tipo de comunicación.</p>   
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <p className="breadcum"><a href="javascript:void(0)">Home</a> <span>&#62;</span> <a href="javascript:void(0)">Contact Us</a></p>
                            </div>
                        </div>
                    </div>		      
                </section>
                <div className="container">
                    <div className="contact-box">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="contact-box__area">
                                    <h5>Quisiera consultar:</h5>
                                    <form onSubmit={this.handleOnContactSubmit}>
                                        <div className="form-group">
                                            <div className="input-wrap">
                                                <span className="login-icon">
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/name-icon.svg`} alt="Name Icon" rel="noopener noreferrer" />
                                                </span>
                                                <input 
                                                type="text" 
                                                className={'form-control' + (submittedContact && !contactName ? ' has-error' : '')} 
                                                placeholder="Introduzca su nombre"  
                                                name="contactName" 
                                                value={contactName} onChange={this.handleOnContactChange}
                                                />
                                            </div>
                                            <span style={{color: "red"}}>{this.state.errors["contactName"]}</span>

                                        </div>
                                        <div className="form-group">
                                            <div className="input-wrap">
                                            <span className="login-icon">
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/mail-icon.svg`} alt="Mail Icon" rel="noopener noreferrer" />
                                            </span>
                                            <input 
                                            type="text" 
                                            className={'form-control' + (submittedContact && !contactEmail ? ' has-error' : '')} 
                                            placeholder="Introduce tu correo electrónico"  
                                            name="contactEmail" 
                                            value={contactEmail} onChange={this.handleOnContactChange}
                                            />
                                            </div>
                                            <span style={{color: "red"}}>{this.state.errors["contactEmail"]}</span>
                                        </div>
                                        <div className="form-group">
                                            <div className="input-wrap">
                                            <span className="login-icon">
                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/phone-icon.svg`} alt="Phone Icon" rel="noopener noreferrer" />
                                            </span>
                                            <input 
                                            type="text" 
                                            className={'form-control' + (submittedContact && !contactPhone ? ' has-error' : '')} 
                                            placeholder="Teléfono o móvil"  
                                            name="contactPhone" 
                                            value={contactPhone} onChange={this.handleOnContactChange}
                                            />
                                            </div>
                                            <span style={{color: "red"}}>{this.state.errors["contactPhone"]}</span>
                                        </div>
                                        <div className="form-group">
                                            <div className="input-wrap">
                                            <span className="login-icon">
                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/message-icon.svg`} alt="Message Icon" rel="noopener noreferrer" />
                                            </span>
                                            <textarea cols="8"
                                            className={'form-control' + (submittedContact && !contactMessage ? ' has-error' : '')} 
                                            placeholder="Ingrese su consulta"  
                                            name="contactMessage" 
                                            onChange={this.handleOnContactChange}
                                            >{contactMessage}</textarea>
                                            </div>
                                            <span style={{color: "red"}}>{this.state.errors["contactMessage"]}</span>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-success" disabled={this.state.contactDisabled}>
                                                <i className={`fa fa-refresh fa-spin ${ this.state.contactLoader }`}></i>
                                                &nbsp; {this.state.contactDisabled ? 'Sending..' : 'Envíar'}
                                                <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/footer-arrow.svg`} alt="arrow" />
                                            </button>
                                            <span style={{color: "green"}}>{this.state.contactApiSuccessResponse}</span>
                                            <span style={{color: "red"}}>{this.state.contactApiErrorResponse}</span>
                                            <a
                                                className="whatsapp"
                                                rel="noopener noreferrer"
                                                href={`https://api.whatsapp.com/send?phone=50764447679`}
                                                target="_blank"
                                            >
                                                <i className="fa fa-whatsapp" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.0007332923647!2d-79.55297800000001!3d8.972078!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8faca8a24b5fb453%3A0x67e82047ba3b5c93!2sE+Vision!5e0!3m2!1ses-419!2s!4v1401929452827' width="100%" height="100%" frameBorder={0} style={{border: "0px"}} allowFullScreen title="map" />
                                <p itemProp="geo" itemScope itemType="http://schema.org/GeoCoordinates">
                                <meta itemProp="latitude" content="8.971685" />
                                <meta itemProp="longitude" content="-79.553182" />
                                </p>
                                {/* <div className="contact-box__right-text">
                                    <div className="contact-box__addressbar">
                                        <h5>E-VISION, S.A.</h5>
                                        <div className="contact-box__bararea w70">
                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/contact-address.svg`} alt="Address" rel="noopener noreferrer" />
                                            <p>Albrook Mall, Pasillo de los Pingüinos, 2o. piso, Local PA-M20, Panamá, Rep. de Panamá</p>
                                        </div>
                                        <div className="contact-box__bararea">
                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/contact-whatsapp.svg`} alt="Whatsapp" rel="noopener noreferrer" />
                                            <p>Whatsapp: <a href="tel:+50764447679">(+507)6444-7679</a></p>
                                        </div>
                                        <div className="contact-box__bararea w70">
                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/contact-website.svg`} alt="Website" rel="noopener noreferrer" />
                                            <p>Website: <a href="/">www.evisionstore.com</a></p>
                                        </div>
                                        <div className="contact-box__bararea">
                                            <img src={`${process.env.PUBLIC_URL}/images/new-images/icon/contact-call.svg`} alt="Call" rel="noopener noreferrer" />
                                            <p>Teléfono: <a href="tel:+507269-1491">269-1491</a></p>
                                        </div>
                                        
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="line-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <hr></hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}