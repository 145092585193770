import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Spinner from "../Include/ComponentLoader";
import API_BASE_URL from "../../config/api";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default class SliderSlick extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannererror: null,
      banners: [],
      banners_right: [],
      loading: true,
    };
  }

  componentDidMount() {
    //const apiUrl = "https://www.evisionstore.com/api/home/dashboard.php";
    const apiUrl = `${API_BASE_URL}/home-banners`;
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ banner_apply_for: "WEB" }),
    };

    fetch(apiUrl, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          //console.log(result)
          this.setState({
            banners: result.data,
            //banners_right: result.top_right_banner,
            loading: false,
          });
        },
        (error) => {
          this.setState({ bannererror: error });
        }
      );
  }

  handleImageClick(link) {
      console.log(link)
      window.open(link, "_blank");
      return false;
      window.location.href = link;  // Navigate to the link
  }

  render() {
    const { banners, banners_right } = this.state;
    const settings = {
      dots: true,
      fade: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      adaptiveHeight: false
    };

    const settings2 = {
      infinite: true,
      fade: false,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
    };

    let content;

    /* if (banners_right != false) {
      content = this.state.loading ? (
        <Spinner />
      ) : (
        <>
          <div className="left_banner_section">
            <Slider {...settings}>
              {banners.length !== 0 ? (
                banners.map((banners) => (
                  <div className="item" key={banners.banner_id}>
                    <a href={banners.banners_link}>
                      <img src={banners.banner_image} alt="Slider 1" className="slide-image" rel="noopener noreferrer" />
                    </a>
                    <div className="bs-slider-overlay" />
                  </div>
                ))
              ) : (
                <></>
              )}
            </Slider>
          </div>
          <div className="right_banner_section">
            <Slider {...settings2}>
              {banners_right.length !== 0 ? (
                banners_right.map((banners_right) => (
                  <div className="item" key={banners_right.banner_id}>
                    <a href={banners_right.banners_link} className="asd">
                      <img src={banners_right.banner_image} alt={banners_right.banner_title} className="slide-image" rel="noopener noreferrer" />
                    </a>
                    <div className="bs-slider-overlay" />
                  </div>
                ))
              ) : (
                <></>
              )}
            </Slider>
          </div>
        </>
      );
    } else { */
      content = this.state.loading ? (
        <Spinner />
      ) : (
        banners.length !== 0 && (
          <Slider {...settings}>
            {banners.map((banner, index) => (
              <div className="item banner-item" id={`eVisionHomeBanner${index+1}`} key={banner.banner_id || index}>
                <a href={banner.banner_link} rel="noopener noreferrer">
                  <img
                    src={banner.banner_image}
                    alt={banner.banner_name}
                    className="slide-image"
                  />
                </a>
                <div className="bs-slider-overlay" />
              </div>
            ))}
          </Slider>
        ));
    //}

    return (
      <section className="main-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="slider-container banner-slider-container">{content}</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
