import React, { Component } from "react";
import { Helmet } from "react-helmet";
import API_BASE_URL from "../../config/api";

const TITLE = "E-Vision / Krediya";

export default class Krediya extends Component {
	constructor(props) {
		super(props);
		this.state = {
			categories: [],
		};
	}

	componentDidMount() {
		fetch(`${API_BASE_URL}/krediya-category`)
			.then((res) => res.json())
			.then((data) => {
				this.setState({
					categories: data.data,
				});
			});
	}

	render() {
		return (
			<div className="wraper">
				<Helmet>
					<title>{TITLE}</title>
				</Helmet>
				<div className="creditcontainer">
					<div className="creditbannerarea">
						<div className="creditbannerleftarea">
							<h1>COMPRA AHORA Y PAGA DESPUÉS CON</h1>
							<p>
								Financiar con KrediYA es fácil, rápido e
								instantáneo
							</p>

							<img
								src="images/krediya/krediyapanama-logo.png"
								alt="KrediYA Panama Logo"
							/>
						</div>
						<div className="creditbannerrightareaimage">
							<img
								src="images/krediya/creditredimg.png"
								alt="Shopping Banner"
							/>
						</div>
					</div>

					<div className="steps">
						<div className="step">
							<img
								src="images/krediya/crediticon1.png"
								alt="Store Icon"
							/>
							<h3>Acercate a una de nuestras tiendas</h3>
							<p>
								Ven a cualquiera de nuestras sucursales o
								escríbenos para comenzar.
							</p>
						</div>

						<div className="step">
							<img
								src="images/krediya/crediticon2.png"
								alt="Store Icon"
							/>
							<h3>Elige tus productos</h3>
							<p>Selecciona los productos que deseas comprar.</p>
						</div>

						<div className="step">
							<img
								src="images/krediya/crediticon3.png"
								alt="Store Icon"
							/>
							<h3>Presenta tu identificación</h3>
							<p>
								Presente su documento de identificación para su
								verificación.
							</p>
						</div>

						<div className="step">
							<img
								src="images/krediya/crediticon4.png"
								alt="Store Icon"
							/>
							<h3>Responda unas breves preguntas</h3>
							<p>
								Complete un formulario de preguntas rápidas y
								fáciles para completar su solicitud
							</p>
						</div>

						<div className="step">
							<img
								src="images/krediya/crediticon5.png"
								alt="Store Icon"
							/>
							<h3>Pague un pequeño abono inicial</h3>
							<p>Paga una pequeña cuota inicial.</p>
						</div>

						<div className="step">
							<img
								src="images/krediya/crediticon6.png"
								alt="Store Icon"
							/>
							<h3>Disfruta de tus nuevos productos</h3>
							<p>
								Llevate a casa tus nuevos productos y empieza a
								disfrutarlos.
							</p>
						</div>
					</div>
				</div>

				<div className="container">
					<div className="fingertips_area">
						<h2>
							Toda la tecnologia de E Vision a un{" "}
							<span>solo click</span>
						</h2>
						<div className="fingertips-cat-area">
							{this.state.categories &&
								this.state.categories.map((category) => (
									<div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 category-similer-box-wrapper">
										<a
											className="category-similer-box-anchore"
											href={category.url}
										>
											<div className="similar-catagory category-similer-box">
												<figure>
													<img
														src={category.image}
														alt="Digital Cameras"
														className="full-width-auto"
													/>
												</figure>
												<h4>{category.name}</h4>
												<div className="category-similer-box-link">
													ver más
													<img
														src="images/krediya/footer-arrow.svg"
														width="20"
														height="16"
														alt="Cart"
													/>
												</div>
											</div>
										</a>
									</div>
								))}
						</div>
						<h3>
							Aplica para tu credito YA{" "}
							<a href="https://api.whatsapp.com/send?phone=50764447679&text=Apply for your loan NOW.">
								Haz Click Aqui
							</a>
						</h3>
					</div>
				</div>
			</div>
		);
	}
}
