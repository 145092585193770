import React from "react";

const AddressLink = ({ address }) => {
  const openGoogleMaps = () => {
    const encodedAddress = encodeURIComponent(address);
    window.open(`https://www.google.com/maps/search/?q=${encodedAddress}`, "_blank");
  };

  return (
    <span 
      onClick={openGoogleMaps} 
      style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
    >
      {address}
    </span>
  );
};

export default AddressLink;