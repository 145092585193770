import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Modal, Form } from "react-bootstrap";
import UpsellSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
//import ReactImageMagnify from "react-image-magnify";
//import { SideBySideMagnifier } from "react-image-magnifiers";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import InnerImageZoom from "react-inner-image-zoom";
import { connect } from "react-redux";
import {
	addToCart2,
	addAttributeToCart,
} from "../../Services/Actions/cart.actions";
import { login2 } from "../../Services/Actions/user.actions";
import { addToQuotation } from "../../Services/Actions/quotation.actions";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import API_BASE_URL from "../../config/api";
import AddressLink from "./AddressLink";
//import {appendLGScript} from './appendLGScript';

const parseQueryString = require("query-string");

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return <div className={className} onClick={onClick} />;
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return <div className={className} onClick={onClick} />;
}

class ProductDetailsListAreaComp extends Component {
	constructor(props) {
		super(props);

		this.state = {
			customerId: this.props.customerId,
			variationProductId: false,
			isProductDetailsError: null,
			isProductsDetailsLoaded: false,
			productBrand: "",
			productAttributes: [],
			productDetails: {},
			productQuantity: 1,
			productMultipleImages: [],
			productMultipleImagesUpdated: [],
			upsellProducts: [],
			clickedProductId: "",
			addToCartUpsellButtonnLoader: "hidden",
			addToCartUpsellButtonnCart: "",
			errors: {},
			apiErrorMessage: "",
			apiSuccessMessage: "",
			isQuanValid: true,
			username: "",
			password: "",
			loginSubmitted: false,
			loginCheck: false,
			firstname: "",
			lastname: "",
			email: "",
			telephone: "",
			fulltelephonenumber: "",
			regpassword: "",
			forgotPassLoader: "hidden",
			registrationLoader: "hidden",
			registrationSubmitted: false,
			forgotPasswordSubmitted: false,
			forgotPasswordApiSuccessResponse: "",
			forgotPasswordApiErrorResponse: "",
			loginFormShowHide: "",
			registrationFormShowHide: "hidden",
			forgotPasswordShowHide: "hidden",
			showNotifyModal: false,
			showLoginModal: false,
			showServiceCenterModal: false,
			notifyProductSubmitted: false,
			notifytoemail: this.props.userEmail,
			notifyProdLoader: "hidden",
			notifyApiSuccessResponse: "",
			ratereviewLoader: "hidden",
			ratereviewtitle: "",
			ratereviewdesc: "",
			customerRatedNumber: "",
			reviewApiSuccessResponse: "",
			addToCartButton: true,
			showUpdateCartMsgModal: false,
			isCartAddError: false,
			cartErrorMessage: "",
			addToCartButtonnLoader: "hidden",
			addToCartButtonnCart: "",
			addToCartButtonDisabled: false,
			addToCartSuccessMsg: "",
			showQuotationModal: false,
			isQuotationSuccess: false,
			quotationDisabled: false,
			selectedAttribute: [],
			isCheckedAllAttributes: false,
			attributesCount: 0,
			selectedAttributesCount: 0,
			isObjetoAvailable: false,
			objetoScript: [],
			customerReviews: [],
			productServiceCenter: [],
			reviewFormSection: "hidden",
			schemVal: false,
			productName: false,
			productDescripcion: false,
			productAlias: false,
			productAttributeLoader: "hidden",
			selItemId: false,
		};
		this.handleOnQuantityChange = this.handleOnQuantityChange.bind(this);
		this.handleOnNotifyEmailInputChange =
			this.handleOnNotifyEmailInputChange.bind(this);
		this.handleOnNotifyProductSubmit =
			this.handleOnNotifyProductSubmit.bind(this);
		this.openNotifyPop = this.openNotifyPop.bind(this);
		this.closeNotifyPop = this.closeNotifyPop.bind(this);
		this.handleOnRateReviewInputChange =
			this.handleOnRateReviewInputChange.bind(this);
		this.handleOnRateAndReviewSubmit =
			this.handleOnRateAndReviewSubmit.bind(this);
		this.handleOnLoginInputChange =
			this.handleOnLoginInputChange.bind(this);
		this.handleOnProductLoginSubmit =
			this.handleOnProductLoginSubmit.bind(this);

		this.handleOnRegistrationInputChange =
			this.handleOnRegistrationInputChange.bind(this);
		this.handleOnRegistrationSubmit =
			this.handleOnRegistrationSubmit.bind(this);
		this.handleOnForgotPasswordSubmit =
			this.handleOnForgotPasswordSubmit.bind(this);

		this.openLoginPop = this.openLoginPop.bind(this);
		this.closeLoginPop = this.closeLoginPop.bind(this);
		this.openServiceCenterPop = this.openServiceCenterPop.bind(this);
		this.closeServiceCenterPop = this.closeServiceCenterPop.bind(this);
		this.cartUpdatePopupResOpen = this.cartUpdatePopupResOpen.bind(this);
		this.cartUpdatePopupResClose = this.cartUpdatePopupResClose.bind(this);
		this.openCartReturnPop = this.openCartReturnPop.bind(this);
		this.closeCartReturnPop = this.closeCartReturnPop.bind(this);
		this.openQuotationPop = this.openQuotationPop.bind(this);
		this.closeQuotationPop = this.closeQuotationPop.bind(this);

		this.handleOnRegistrationTelInputChange =
			this.handleOnRegistrationTelInputChange.bind(this);

		this.rateReviewDivFocus = React.createRef();
		this.rateSubmitReviewDivFocus = React.createRef();
	}

	handleOnRegistrationTelInputChange(
		isValid,
		newNumber,
		countryData,
		fullNumber,
		isExtension
	) {
		this.setState({ telephone: newNumber });
		this.setState({ fulltelephonenumber: fullNumber });
	}

	handleOnClick = (event) => {
		//.current is verification that your element has rendered
		if (this.rateReviewDivFocus.current) {
			this.rateReviewDivFocus.current.scrollIntoView({
				behavior: "smooth",
				//block: "nearest"
			});
		}
	};

	handleOnClickToReview = (event) => {
		//.current is verification that your element has rendered

		setTimeout(
			() =>
				this.setState(
					{
						reviewFormSection: "",
					},
					() => {
						if (this.state.reviewFormSection === "") {
							if (this.rateSubmitReviewDivFocus.current) {
								this.rateSubmitReviewDivFocus.current.scrollIntoView(
									{
										behavior: "smooth",
										block: "nearest",
									}
								);
							}
						}
					}
				),
			200
		);
	};

	handleOnQuantityChange(e) {
		const { name, value } = e.target;
		const regex = /^[0-9\b]+$/;
		if (value === "" || regex.test(value)) {
			this.setState({ [name]: value });
		}
	}

	handleOnRegistrationInputChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	handleQuantityValidation() {
		let getProductQuantity = this.state.productQuantity;
		let isQuanValid = true;

		if (!getProductQuantity) {
			isQuanValid = false;
		} else {
			const re = /^[0-9\b]+$/;
			if (re.test(getProductQuantity) && getProductQuantity <= 100) {
				isQuanValid = true;
			} else {
				isQuanValid = false;
			}
		}
		this.setState({ isQuanValid: isQuanValid });
		return isQuanValid;
	}

	handleOnAddToCart(
		product_id,
		product_name,
		product_image,
		price,
		brand,
		modelo,
		category_name,
		is_allowed_bac_credomatic
	) {
		if (this.handleQuantityValidation()) {
			this.setState({
				addToCartButtonnLoader: "",
				addToCartButtonnCart: "hidden",
				addToCartButtonDisabled: true,
				addToCartSuccessMsg: "",
			});
			const quantity = this.state.productQuantity;
			let checked_attribute = this.state.selectedAttribute;

			let checked_variation_product_id = 0;
			if (this.state.variationProductId) {
				checked_variation_product_id = this.state.variationProductId;
			}

			//console.log(checked_attribute)
			this.props.addToCart(
				product_id,
				product_name,
				product_image,
				price,
				quantity,
				checked_variation_product_id,
				modelo,
				is_allowed_bac_credomatic,
				checked_attribute
			);
			//console.log(checked_variation_product_id)
			if (checked_attribute && checked_attribute.length > 0) {
				this.props.addAttributeToCart(
					product_id,
					checked_variation_product_id,
					checked_attribute
				);
			}

			setTimeout(
				() =>
					this.setState(
						{
							isCartAddError: this.props.isCartAddError,
						},
						() => {
							if (this.state.isCartAddError) {
								this.setState({
									cartErrorMessage:
										this.props.cartErrorMessage,
									addToCartButtonnLoader: "hidden",
									addToCartButtonnCart: "",
									addToCartButtonDisabled: false,
								});
								this.cartUpdatePopupResOpen();
								setTimeout(
									() => this.cartUpdatePopupResClose(),
									5000
								);
							} else {
								this.openCartReturnPop();
								this.setState({
									addToCartSuccessMsg:
										quantity +
										' x "' +
										product_name +
										'" have been added to your cart.',
									cartErrorMessage: "",
									addToCartButtonnLoader: "hidden",
									addToCartButtonnCart: "",
									addToCartButtonDisabled: false,
								});
								setTimeout(
									() =>
										this.setState({
											addToCartSuccessMsg: "",
										}),
									5000
								);

								window.fbq("track", "AddToCart", {
									content_ids: [product_id],
									content_type: "product",
									value: price,
									currency: "USD",
								});

								window.ga("ec:addProduct", {
									id: modelo,
									name: brand + " " + modelo,
									price: price,
									brand: brand,
									category: category_name,
									position: 0,
									quantity: 1,
								});
								window.ga("ec:setAction", "add");
								window.ga(
									"send",
									"event",
									"Product List Page",
									"click",
									"addToCart"
								);

								window.gtag("event", "add_to_cart", {
									send_to: "UA-521381-2/yuuFCLjX-aUBENbiovUC",
									value: price,
									currency: "USD",
									event_callback: true,
								});
							}
						}
					),
				3000
			);
		}
	}

	handleOnUpsellAddToCart(
		product_id,
		product_name,
		product_image,
		price,
		quantity,
		brand,
		modelo,
		category_name
	) {
		this.setState({
			addToCartUpsellButtonnLoader: "",
			addToCartUpsellButtonnCart: "hidden",
			addToCartButton: true,
			addToCartButtonDisabled: true,
			cartErrorMessage: "",
			clickedProductId: product_id,
		});
		let checked_variation_product_id = 0;
		this.props.addToCart(
			product_id,
			product_name,
			product_image,
			price,
			quantity,
			checked_variation_product_id,
			modelo
		);

		setTimeout(
			() =>
				this.setState(
					{
						isCartAddError: this.props.isCartAddError,
					},
					() => {
						if (this.state.isCartAddError) {
							this.setState({
								cartErrorMessage: this.props.cartErrorMessage,
								addToCartUpsellButtonnLoader: "hidden",
								addToCartUpsellButtonnCart: "",
								addToCartButton: true,
								addToCartButtonDisabled: false,
								clickedProductId: "",
							});
							this.cartUpdatePopupResOpen();
							setTimeout(
								() => this.cartUpdatePopupResClose(),
								5000
							);
						} else {
							this.openCartReturnPop();
							this.setState({
								addToCartUpsellButtonnLoader: "hidden",
								addToCartUpsellButtonnCart: "",
								addToCartButton: false,
								addToCartButtonDisabled: false,
								cartErrorMessage: "",
							});

							window.fbq("track", "AddToCart", {
								content_ids: [product_id],
								content_type: "product",
								value: price,
								currency: "USD",
							});

							window.ga("ec:addProduct", {
								id: modelo,
								name: brand + " " + modelo,
								price: price,
								brand: brand,
								category: category_name,
								quantity: 1,
							});
							window.ga("ec:setAction", "add");
							window.ga(
								"send",
								"event",
								"Product View Page",
								"click",
								"addToCart"
							);

							window.gtag("event", "add_to_cart", {
								send_to: "UA-521381-2/yuuFCLjX-aUBENbiovUC",
								value: price,
								currency: "USD",
								event_callback: true,
							});
						}
					}
				),
			3000
		);
	}

	handleOnAddToQuotation(
		product_id,
		product_name,
		product_image,
		product_price,
		product_short_desc
	) {
		this.setState({ quotationDisabled: true });

		const product_quantity = this.state.productQuantity;
		this.props.addToQuotation(
			product_id,
			product_name,
			product_image,
			product_price,
			product_quantity,
			product_short_desc
		);

		setTimeout(
			() =>
				this.setState(
					{
						isQuotationSuccess: this.props.isQuotationAdded,
					},
					() => {
						if (this.state.isQuotationSuccess) {
							this.setState({
								showQuotationModal: true,
								quotationDisabled: false,
							});
						} else {
							this.setState({
								showQuotationModal: false,
								quotationDisabled: false,
							});
						}
					}
				),
			1000
		);
	}

	componentDidMount() {
		window.Intercom("update", {
			hide_default_launcher: true,
		});
		//let urlhash = this.props.location.hash;
		//window.scrollTo(100, 500);
		let product_id = this.props.match.params.productId;

		//const apiUrl = "https://www.evisionstore.com/api/product/view-react.php";
		const apiUrl = `${API_BASE_URL}/product-detail`;
		let requestOptions;
		if (isNaN(product_id)) {
			requestOptions = {
				method: "POST",
				body: JSON.stringify({
					model_number: product_id,
					source_type: "web",
				}),
			};
		} else {
			requestOptions = {
				method: "POST",
				body: JSON.stringify({
					product_id: product_id,
					source_type: "web",
				}),
			};
		}
		fetch(apiUrl, requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					//console.log(result)
					if (result.code === 200) {
						this.setState(
							{
								productDetailsResponseBoolean: true,
								productDetails: result.product_view,
								productMultipleImages:
									result.data.multiple_images,
								isProductsDetailsLoaded: true,
								addToCartOption:
									result.data.product_view[0]
										.addtocart_option,
								brandLink:
									result.data.product_view[0].brand_link,
								categoryId:
									result.data.product_view[0].category_id,
								categorySlug:
									result.data.product_view[0].category_slug,
								categoryLink:
									result.data.product_view[0].category_link,
								categoryName:
									result.data.product_view[0].category_name,
								currency: result.data.product_view[0].currency,
								descripcion:
									result.data.product_view[0].description,
								isAllowedBacCredomatic:
									result.data.product_view[0]
										.is_allowed_bac_credomatic,
								bacCredomaticOptions:
									result.data.product_view[0]
										.bac_credomatic_options,
								isVariationDisplay:
									result.data.product_view[0]
										.is_variation_display,
								price: result.data.product_view[0].price,
								showPrice:
									result.data.product_view[0].show_price,
								regularPrice:
									result.data.product_view[0].regular_price,
								productId:
									result.data.product_view[0].product_id,
								productImage:
									result.data.product_view[0].product_image,
								productMediumImage:
									result.data.product_view[0]
										.product_medium_image,
								productName:
									result.data.product_view[0].product_name,
								quantity: result.data.product_view[0].qty,
								shortDescription:
									result.data.product_view[0]
										.short_description,
								specialPrice:
									result.data.product_view[0].special_price,
								productBrand: result.data.product_view[0].brand,
								productMarca: result.data.product_view[0].marca,
								productModelo:
									result.data.product_view[0].modelo,
								productModeloCapital:
									result.data.product_view[0].modeloCapital,
								productModel:
									result.data.product_view[0].modelo.replace(
										/\//g,
										""
									),
								productAttributes:
									result.data.product_view[0].attribute_data,
								selectedAttribute:
									result.data.product_view[0]
										.attribute_selected_terms,
								attributesCount:
									result.data.product_view[0].attribute_count,

								productRating:
									result.data.product_view[0].product_rating,
								totalCustomerReviews:
									result.data.product_view[0]
										.total_customer_reviews,
								fiveStarRating:
									result.data.product_view[0]
										.five_star_rating,
								fourStarRating:
									result.data.product_view[0]
										.four_star_rating,
								threeStarRating:
									result.data.product_view[0]
										.three_star_rating,
								twoStarRating:
									result.data.product_view[0].two_star_rating,
								oneStarRating:
									result.data.product_view[0].one_star_rating,
								fiveStarRatingPercentage:
									result.data.product_view[0]
										.five_star_rating_percentage,
								fourStarRatingPercentage:
									result.data.product_view[0]
										.four_star_rating_percentage,
								threeStarRatingPercentage:
									result.data.product_view[0]
										.three_star_rating_percentage,
								twoStarRatingPercentage:
									result.data.product_view[0]
										.two_star_rating_percentage,
								oneStarRatingPercentage:
									result.data.product_view[0]
										.one_star_rating_percentage,

								isObjetoAvailable:
									result.data.product_view[0]
										.is_objeto_available,
								objetoScript:
									result.data.product_view[0].objeto_script,
								isIframeObjetoAvailable:
									result.data.product_view[0]
										.is_iframe_objeto_available,
								iframeObjetoScript:
									result.data.product_view[0]
										.iframe_objeto_script,

								customerReviews:
									result.data.product_view[0]
										.customer_reviews,
								productServiceCenter:
									result.data.service_centers,

								upsellProducts: result.data.upsell_products,
								productMetas:
									result.data.product_view[0].product_metas,
							},
							() => {
								if (this.state.productBrand === "samsung") {
									window.Intercom("update", {
										hide_default_launcher: false,
									});
									window.Intercom("boot", {
										app_id: "puw23zdb",
									});
								}

								if (this.state.addToCartOption !== 0) {
									this.setState({
										schemVal: "https://schema.org/InStock",
									});
								} else {
									this.setState({
										schemVal:
											"https://schema.org/OutOfStock",
									});
								}

								let productMarca = this.state.productMarca;
								let productModelo = this.state.productModelo;

								const script = document.createElement("script");
								script.type = "text/javascript";
								script.async = true;
								//script.src = "https://media.flixfacts.com/js/loader.js";
								script.setAttribute(
									"data-flix-distributor",
									"14613"
								);
								script.setAttribute("data-flix-language", "PA");
								script.setAttribute(
									"data-flix-brand",
									productMarca
								);
								script.setAttribute(
									"data-flix-mpn",
									productModelo
								);
								script.setAttribute("data-flix-ean", "");
								script.setAttribute("data-flix-sku", "");
								script.setAttribute(
									"data-flix-button",
									"flix-minisite"
								);
								script.setAttribute(
									"data-flix-inpage",
									"flix-inpage"
								);
								script.setAttribute(
									"data-flix-button-image",
									""
								);
								script.setAttribute("data-flix-price", "");
								script.setAttribute(
									"data-flix-fallback-language",
									"t2"
								);
								script.src =
									"https://media.flixfacts.com/js/loader.js";
								var s =
									document.getElementsByTagName("script")[0];
								s.parentNode.insertBefore(script, s);
								//document.head.appendChild(script);
							}
						);
					} else {
						this.setState({
							isProductsDetailsLoaded: true,
							productDetailsResponseBoolean: false,
							productDetailsErrorMsg: result.message,
						});
					}
				},
				(error) => {
					this.setState({ isProductDetailsError: error });
				}
			);

		let queryString = this.props.location.search;
		let queryParams = parseQueryString.parse(queryString);
		let brand_name = "";

		if (Object.keys(queryParams).length > 0) {
			brand_name = queryParams.brand;
		}

		if (brand_name === "lg") {
			//appendLGScript();
			window.callbellSettings = { token: "74N8o9CYVFtMpoR25CrZnQaz" };

			// window.callbellSettings('boot', {
			//     token: '74N8o9CYVFtMpoR25CrZnQaz'
			// });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// only update chart if the data has changed
		if (
			prevProps.match.params.productId !==
			this.props.match.params.productId
		) {
			window.Intercom("update", {
				hide_default_launcher: true,
			});

			this.setState({
				isProductsDetailsLoaded: false,
				productDetailsResponseBoolean: false,
				isObjetoAvailable: false,
			});

			let product_id = this.props.match.params.productId;
			//const apiUrl = "https://www.evisionstore.com/api/product/view-react.php";
			const apiUrl = `${API_BASE_URL}/product-detail`;
			let requestOptions;
			if (isNaN(product_id)) {
				requestOptions = {
					method: "POST",
					body: JSON.stringify({
						model_number: product_id,
						source_type: "web",
					}),
				};
			} else {
				requestOptions = {
					method: "POST",
					body: JSON.stringify({
						product_id: product_id,
						source_type: "web",
					}),
				};
			}
			fetch(apiUrl, requestOptions)
				.then((res) => res.json())
				.then(
					(result) => {
						if (result.code === 200) {
							this.setState(
								{
									productDetailsResponseBoolean: true,
									productDetails: result.data.product_view,
									productMultipleImages:
										result.data.multiple_images,
									isProductsDetailsLoaded: true,
									addToCartOption:
										result.data.product_view[0]
											.addtocart_option,
									brandLink:
										result.data.product_view[0].brand_link,
									categoryId:
										result.data.product_view[0].category_id,
									categorySlug:
										result.data.product_view[0]
											.category_slug,
									categoryLink:
										result.data.product_view[0]
											.category_link,
									categoryName:
										result.data.product_view[0]
											.category_name,
									currency:
										result.data.product_view[0].currency,
									descripcion:
										result.data.product_view[0].description,
									isAllowedBacCredomatic:
										result.data.product_view[0]
											.is_allowed_bac_credomatic,
									bacCredomaticOptions:
										result.data.product_view[0]
											.bac_credomatic_options,
									price: result.data.product_view[0].price,
									showPrice:
										result.data.product_view[0].show_price,
									regularPrice:
										result.data.product_view[0]
											.regular_price,
									productId:
										result.data.product_view[0].product_id,
									productImage:
										result.data.product_view[0]
											.product_image,
									productMediumImage:
										result.data.product_view[0]
											.product_medium_image,
									productName:
										result.data.product_view[0]
											.product_name,
									quantity: result.data.product_view[0].qty,
									shortDescription:
										result.data.product_view[0]
											.short_description,
									specialPrice:
										result.data.product_view[0]
											.special_price,
									productBrand:
										result.data.product_view[0].brand,
									productMarca:
										result.data.product_view[0].marca,
									productModelo:
										result.data.product_view[0].modelo,
									productModeloCapital:
										result.data.product_view[0]
											.modeloCapital,
									productModel:
										result.data.product_view[0].modelo.replace(
											/\//g,
											""
										),
									productAttributes:
										result.data.product_view[0]
											.attribute_data,

									productRating:
										result.data.product_view[0]
											.product_rating,
									totalCustomerReviews:
										result.data.product_view[0]
											.total_customer_reviews,
									fiveStarRating:
										result.data.product_view[0]
											.five_star_rating,
									fourStarRating:
										result.data.product_view[0]
											.four_star_rating,
									threeStarRating:
										result.data.product_view[0]
											.three_star_rating,
									twoStarRating:
										result.data.product_view[0]
											.two_star_rating,
									oneStarRating:
										result.data.product_view[0]
											.one_star_rating,
									fiveStarRatingPercentage:
										result.data.product_view[0]
											.five_star_rating_percentage,
									fourStarRatingPercentage:
										result.data.product_view[0]
											.four_star_rating_percentage,
									threeStarRatingPercentage:
										result.data.product_view[0]
											.three_star_rating_percentage,
									twoStarRatingPercentage:
										result.data.product_view[0]
											.two_star_rating_percentage,
									oneStarRatingPercentage:
										result.data.product_view[0]
											.one_star_rating_percentage,

									customerReviews:
										result.data.product_view[0]
											.customer_reviews,

									isObjetoAvailable:
										result.data.product_view[0]
											.is_objeto_available,
									objetoScript:
										result.data.product_view[0]
											.objeto_script,
									productServiceCenter:
										result.data.service_centers,
									upsellProducts: result.data.upsell_products,
									productMetas:
										result.data.product_view[0]
											.product_metas,
								},
								() => {
									if (this.state.productBrand === "samsung") {
										window.Intercom("update", {
											hide_default_launcher: false,
										});
										window.Intercom("boot", {
											app_id: "puw23zdb",
										});
									}

									if (this.state.addToCartOption !== 0) {
										this.setState({
											schemVal:
												"https://schema.org/InStock",
										});
									} else {
										this.setState({
											schemVal:
												"https://schema.org/OutOfStock",
										});
									}
								}
							);
						} else {
							this.setState({
								isProductsDetailsLoaded: true,
								productDetailsResponseBoolean: false,
								productDetailsErrorMsg: result.message,
							});
						}
					},
					(error) => {
						this.setState({ isProductDetailsError: error });
					}
				);

			let queryString = this.props.location.search;
			let queryParams = parseQueryString.parse(queryString);
			let brand_name = "";

			if (Object.keys(queryParams).length > 0) {
				brand_name = queryParams.brand;
			}

			// if(brand_name==="lg") {
			//     const script3 = document.createElement("script");
			//     //script.async = true;
			//     script3.id = "lgScript1";
			//     script3.innerHTML = "window.callbellSettings = {token: '74N8o9CYVFtMpoR25CrZnQaz'};"
			//     document.head.appendChild(script3);

			//     const script4 = document.createElement("script");
			//     //script2.async = true;
			//     script4.id = "lgScript2";
			//     script4.innerHTML = "(function(){var w=window;var ic=w.callbell;if(typeof ic==='function'){ic('reattach_activator');ic('update',callbellSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Callbell=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://dash.callbell.eu/include/'+window.callbellSettings.token+'.js';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()"
			//     document.head.appendChild(script4);
			// }
		}

		if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
			const isLoggedIn = this.props.isLoggedIn;
			if (isLoggedIn) {
				this.setState({
					username: "",
					password: "",
				});

				setTimeout(() => this.closeLoginPop(), 2000);
			}
		}
	}

	openNotifyPop() {
		this.setState({ showNotifyModal: true });
	}

	closeNotifyPop() {
		this.setState({ showNotifyModal: false });
	}

	openCartReturnPop() {
		this.setState({ showCartReturnModal: true });
	}

	closeCartReturnPop() {
		this.setState({ showCartReturnModal: false });
	}

	openQuotationPop() {
		this.setState({ showQuotationModal: true });
	}

	closeQuotationPop() {
		this.setState({ showQuotationModal: false });
	}

	cartUpdatePopupResOpen() {
		this.setState({ showUpdateCartMsgModal: true });
	}

	cartUpdatePopupResClose() {
		this.setState({ showUpdateCartMsgModal: false });
	}

	handleOnNotifyEmailInputChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	handleNotifyProductFormValidation() {
		let notifytoemail = this.state.notifytoemail;
		let errors = {};
		let notifyProductFormIsValid = true;

		if (typeof notifytoemail !== "undefined") {
			let lastAtPos = notifytoemail.lastIndexOf("@");
			let lastDotPos = notifytoemail.lastIndexOf(".");

			if (
				!(
					lastAtPos < lastDotPos &&
					lastAtPos > 0 &&
					notifytoemail.indexOf("@@") === -1 &&
					lastDotPos > 2 &&
					notifytoemail.length - lastDotPos > 2
				)
			) {
				notifyProductFormIsValid = false;
				errors["notifytoemail"] =
					"Your registered email id is not valid";
			}
		} else {
			notifyProductFormIsValid = false;
			errors["notifytoemail"] = "Please enter your registered email id";
		}

		this.setState({ errors: errors });
		return notifyProductFormIsValid;
	}

	handleOnNotifyProductSubmit(e) {
		e.preventDefault();
		this.setState({ notifyProductSubmitted: true });

		if (this.handleNotifyProductFormValidation()) {
			this.setState({ notifyProdLoader: "" });
			let customer_email = this.state.notifytoemail;
			//let product_id = this.props.match.params.productId;
			let product_id = this.state.productId;

			//const apiUrl = "https://www.evisionstore.com/api/user/account/notifyproemail.php";
			const apiUrl = `${API_BASE_URL}/notify-product`;

			fetch(apiUrl, {
				method: "POST",
				body: JSON.stringify({
					customer_email: customer_email,
					product_id: product_id,
				}),
			})
				.then((res) => res.json())
				.then(
					(result) => {
						if (result.code === 200) {
							//console.log(result);
							this.setState({
								notifyApiSuccessResponse: result.message,
								notifyProdLoader: "hidden",
							});
						} else {
							this.setState({
								notifyApiSuccessResponse: result.message,
								notifyProdLoader: "hidden",
							});
						}
					},
					(error) => {
						this.setState({ error });
					}
				);
		}
	}

	handleOnRateAndReviewClick(rateNumber) {
		this.setState({ customerRatedNumber: rateNumber });
	}

	handleOnRateReviewInputChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	handleRateReviewValidation() {
		let ratereviewtitle = this.state.ratereviewtitle;
		let ratereviewdesc = this.state.ratereviewdesc;
		let customerRatedNumber = this.state.customerRatedNumber;
		let errors = {};
		let rateReview = true;

		if (!customerRatedNumber) {
			rateReview = false;
			errors["customerRatedNumber"] =
				"Seleccione una de las tarifas de estrellas del producto.";
		}

		if (!ratereviewtitle) {
			rateReview = false;
			errors["ratereviewtitle"] = "Ingrese el título de la reseña.";
		}

		if (!ratereviewdesc) {
			rateReview = false;
			errors["ratereviewdesc"] = "Por favor ingrese sus comentarios.";
		}

		this.setState({ errors: errors });
		return rateReview;
	}

	handleOnRateAndReviewSubmit(e) {
		e.preventDefault();
		this.setState({ rateAndReviewSubmitted: true });
		//console.log(this.handleRateReviewValidation())
		if (this.handleRateReviewValidation()) {
			this.setState({ ratereviewLoader: "" });
			let customer_id = this.state.customerId;
			//let product_id = this.props.match.params.productId;
			let product_id = this.state.productId;
			let rating_number = this.state.customerRatedNumber;
			let review_title = this.state.ratereviewtitle;
			let review_comment = this.state.ratereviewdesc;

			//const apiUrl = "https://www.evisionstore.com/api/user/account/customer_review.php";
			const apiUrl = `${API_BASE_URL}/reviews`;

			fetch(apiUrl, {
				method: "POST",
				body: JSON.stringify({
					customer_id: customer_id,
					product_id: product_id,
					rating_number: rating_number,
					review_title: review_title,
					review_comment: review_comment,
				}),
			})
				.then((res) => res.json())
				.then(
					(result) => {
						if (result.code === 200) {
							//console.log(result);
							this.setState({
								reviewApiSuccessResponse: result.message,
								ratereviewLoader: "hidden",
								customerRatedNumber: "",
								ratereviewtitle: "",
								ratereviewdesc: "",
							});
						} else {
							this.setState({
								reviewApiSuccessResponse: result.message,
								ratereviewLoader: "hidden",
							});
						}
					},
					(error) => {
						this.setState({ error });
					}
				);
		}
	}

	openServiceCenterPop() {
		this.setState({ showServiceCenterModal: true });
	}
	closeServiceCenterPop() {
		this.setState({ showServiceCenterModal: false });
	}
	openLoginPop() {
		this.setState({ showLoginModal: true });
	}

	closeLoginPop() {
		this.setState({ showLoginModal: false });
	}

	handleOnLoginInputChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	handleProductLoginFormValidation() {
		let username = this.state.username;
		let password = this.state.password;
		let errors = {};
		let loginFormIsValid = true;

		if (!username) {
			loginFormIsValid = false;
			errors["username"] = "Email can not be empty";
		}

		if (typeof username !== "undefined") {
			let lastAtPos = username.lastIndexOf("@");
			let lastDotPos = username.lastIndexOf(".");

			if (
				!(
					lastAtPos < lastDotPos &&
					lastAtPos > 0 &&
					username.indexOf("@@") === -1 &&
					lastDotPos > 2 &&
					username.length - lastDotPos > 2
				)
			) {
				loginFormIsValid = false;
				errors["username"] = "Email is not valid";
			}
		}

		if (!password) {
			loginFormIsValid = false;
			errors["password"] = "Password can not be empty";
		}

		this.setState({ errors: errors });
		return loginFormIsValid;
	}

	handleOnProductLoginSubmit(e) {
		e.preventDefault();
		this.setState({ loginSubmitted: true });

		if (this.handleProductLoginFormValidation()) {
			const username = this.state.username;
			const password = this.state.password;
			const cartItems = this.props.cartData;
			this.props.logIn(username, password, cartItems);
		}
	}

	handleRegistrationFormValidation() {
		let firstname = this.state.firstname;
		let lastname = this.state.lastname;
		let email = this.state.email;
		let telephone = this.state.telephone;
		let regpassword = this.state.regpassword;
		let errors = {};
		let registrationFormIsValid = true;

		if (!firstname) {
			registrationFormIsValid = false;
			errors["firstname"] = "Please enter your first name";
		}

		if (!lastname) {
			registrationFormIsValid = false;
			errors["lastname"] = "Please enter your last name";
		}

		if (!telephone) {
			registrationFormIsValid = false;
			errors["telephone"] = "Please enter your phone number.";
		} else {
			if (telephone.length < 6) {
				registrationFormIsValid = false;
				errors["telephone"] = "Please enter your valid phone number.";
			}
		}

		if (typeof email !== "undefined") {
			let lastAtPos = email.lastIndexOf("@");
			let lastDotPos = email.lastIndexOf(".");

			if (
				!(
					lastAtPos < lastDotPos &&
					lastAtPos > 0 &&
					email.indexOf("@@") === -1 &&
					lastDotPos > 2 &&
					email.length - lastDotPos > 2
				)
			) {
				registrationFormIsValid = false;
				errors["email"] = "Email id is not valid";
			}
		} else {
			registrationFormIsValid = false;
			errors["email"] = "Please enter your username.";
		}

		if (!regpassword) {
			registrationFormIsValid = false;
			errors["regpassword"] = "Password can not be empty";
		} else {
			if (regpassword.length < 3) {
				registrationFormIsValid = false;
				errors["regpassword"] =
					"Please enter minimum 4 characters password.";
			}
		}

		this.setState({ errors: errors });
		return registrationFormIsValid;
	}

	handleOnRegistrationSubmit(e) {
		e.preventDefault();
		this.setState({ registrationSubmitted: true });

		if (this.handleRegistrationFormValidation()) {
			this.setState({
				registrationLoader: "",
				loginFormButtonDisabled: true,
			});
			const first_name = this.state.firstname;
			const last_name = this.state.lastname;
			const email = this.state.email;
			const password = this.state.regpassword;
			//const telephone = this.state.telephone;
			const fulltelephonenumber = this.state.fulltelephonenumber;

			this.props.signup(
				first_name,
				last_name,
				email,
				password,
				fulltelephonenumber
			);

			setTimeout(
				() =>
					this.setState(
						{
							loginCheck: this.props.isLoggedIn,
						},
						() => {
							if (this.state.loginCheck) {
								this.setState({
									apiSuccessMessage:
										"Your account has been successfully registered on Evision.",
									loginFormButtonDisabled: false,
									registrationLoader: "hidden",
								});
								setTimeout(() => this.close(), 2000);
							}
							if (this.props.isSignupError) {
								this.setState({
									apiErrorMessage:
										this.props.signupErrorMessage,
									loginFormButtonDisabled: false,
									registrationLoader: "hidden",
								});
							}
						}
					),
				2000
			);
		}
	}

	handleForgotPassFormValidation() {
		let forgotpassemail = this.state.forgotpassemail;
		let errors = {};
		let forgotPasswordFormIsValid = true;

		if (typeof forgotpassemail !== "undefined") {
			let lastAtPos = forgotpassemail.lastIndexOf("@");
			let lastDotPos = forgotpassemail.lastIndexOf(".");

			if (
				!(
					lastAtPos < lastDotPos &&
					lastAtPos > 0 &&
					forgotpassemail.indexOf("@@") === -1 &&
					lastDotPos > 2 &&
					forgotpassemail.length - lastDotPos > 2
				)
			) {
				forgotPasswordFormIsValid = false;
				errors["forgotpassemail"] =
					"Your registered email id is not valid";
			}
		} else {
			forgotPasswordFormIsValid = false;
			errors["forgotpassemail"] = "Please enter your registered email id";
		}

		this.setState({ errors: errors });
		return forgotPasswordFormIsValid;
	}

	handleOnForgotPasswordSubmit(e) {
		e.preventDefault();
		this.setState({ forgotPasswordSubmitted: true });

		if (this.handleForgotPassFormValidation()) {
			this.setState({
				forgotPassLoader: "",
				loginFormButtonDisabled: true,
			});
			let forgotpassemail = this.state.forgotpassemail;

			//const apiUrl = "https://www.evisionstore.com/api/user/forgotpassword.php";
			const apiUrl = `${API_BASE_URL}/forgot-password`;

			fetch(apiUrl, {
				method: "POST",
				body: JSON.stringify({
					email: forgotpassemail,
				}),
			})
				.then((res) => res.json())
				.then(
					(result) => {
						if (result.code === 200) {
							this.setState({
								forgotPasswordApiSuccessResponse:
									result.message,
								forgotPassLoader: "hidden",
								loginFormButtonDisabled: false,
							});
						} else {
							this.setState({
								forgotPasswordApiErrorResponse: result.message,
								forgotPassLoader: "hidden",
								loginFormButtonDisabled: false,
							});
						}
					},
					(error) => {
						this.setState({
							error,
							forgotPassLoader: "hidden",
							loginFormButtonDisabled: false,
						});
					}
				);
		}
	}

	handleIncrement = () => {
		this.setState((prevState) => ({
			productQuantity: prevState.productQuantity + 1,
		}));
	};

	handleDecrement = () => {
		this.setState((prevState) => ({
			productQuantity: Math.max(prevState.productQuantity - 1, 1),
		}));
	};

	handleOnFormChange(buttonParam) {
		if (buttonParam === "registration") {
			this.setState({
				loginFormShowHide: "hidden",
				registrationFormShowHide: "",
				forgotPasswordShowHide: "hidden",
			});
		} else if (buttonParam === "login") {
			this.setState({
				loginFormShowHide: "",
				registrationFormShowHide: "hidden",
				forgotPasswordShowHide: "hidden",
			});
		} else if (buttonParam === "forgotpassword") {
			this.setState({
				loginFormShowHide: "hidden",
				registrationFormShowHide: "hidden",
				forgotPasswordShowHide: "",
			});
		}
	}

	htmlDecode(html) {
		return html.replace(/&([a-z]+);/gi, (match, entity) => {
			const entities = {
				amp: "&",
				apos: "'",
				gt: ">",
				lt: "<",
				nbsp: "\xa0",
				quot: '"',
			};
			entity = entity.toLowerCase();
			if (entities.hasOwnProperty(entity)) {
				return entities[entity];
			}
			return match;
		});
	}

	financial = (x) => Number.parseFloat(x).toFixed(2);
	financialSingle = (x) => Number.parseFloat(x).toFixed(1);

	handleVariation(attributeId, termId, termSlug) {
		this.setState((prevState) => {
			return {
				selectedAttributesCount: prevState.selectedAttributesCount + 1,
			};
		});

		//let product_id = this.props.match.params.productId;
		let product_id = this.state.productId;
		const apiUrl =
			"https://www.evisionstore.com/api/product/selectattribute-react-beta.php";

		let selectedAttribute = this.state.selectedAttribute;
		let addedItem = selectedAttribute.find(
			(item) => item.attributeId === selectedAttribute.attributeId
		);
		let existed_item = selectedAttribute.find(
			(item) => selectedAttribute.attributeId === item.attributeId
		);

		if (existed_item) {
			this.setState({ productAttributeLoader: "" });
			addedItem[attributeId] = termSlug;
			let checked_attribute = this.state.selectedAttribute;
			const requestOptions = {
				method: "POST",
				body: JSON.stringify({
					product_id: product_id,
					checked_attribute: checked_attribute,
				}),
			};
			fetch(apiUrl, requestOptions)
				.then((res) => res.json())
				.then(
					(result) => {
						//console.log(result)
						this.setState({
							variationProductId: result.variation_product_id,
							price: result.price,
							showPrice: result.show_price,
							regularPrice: result.regular_price,
							specialPrice: result.special_price,
							productImage: result.product_image,
							productName: result.product_model_number,
							productDescripcion: result.product_description,
							productAlias: result.product_alias,
							productStock: result.product_stock_quantity,
							isCheckedAllAttributes: result.is_checked_all_attr,
							productMultipleImages: result.multiple_images,
							productAttributeLoader: "hidden",
							selItemId: 0,
						});
					},
					(error) => {
						this.setState({ productAttributeLoader: "hidden" });
					}
				);
		}
	}

	Capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	starRatings(rating_numbers) {
		let whole = Math.floor(rating_numbers);
		let fraction = rating_numbers - whole;
		let dec = 0;

		if (fraction < 0.25) {
			dec = 0;
		} else if (fraction >= 0.25 && fraction < 0.75) {
			dec = 0.5;
		} else if (fraction >= 0.75) {
			dec = 1;
		}
		let r = whole + dec;

		//As we sometimes round up, we split again
		let stars = [];
		let newwhole = Math.floor(r);
		fraction = r - newwhole;
		let j = 0;
		let s = 1;
		for (s; s <= newwhole; s++) {
			stars.push(<span className="staricon staricon-star-filled" />);
			j = j + 1;
		}
		if (fraction === 0.5) {
			stars.push(<span className="staricon staricon-star-half" />);
			let k = 1;
			for (k; k <= 4 - j; k++) {
				stars.push(<span className="staricon staricon-star-empty" />);
			}
		} else {
			let k = 1;
			for (k = 1; k <= 5 - j; k++) {
				stars.push(<span className="staricon staricon-star-empty" />);
			}
		}
		return stars;
	}

	escapeString(str) {
		return str
			.replace(/\\/g, "\\\\") // Escape backslashes
			.replace(/"/g, '\\"') // Escape double quotes
			.replace(/'/g, "\\'") // Escape single quotes
			.replace(/\n/g, "\\n"); // Escape new lines (optional)
	}

	render() {
		const {
			isProductsDetailsLoaded,
			productBrand,
			productDetails,
			productQuantity,
			productAttributes,
			productMetas,
			productMultipleImages,
			upsellProducts,
			notifyProductSubmitted,
			notifytoemail,
			ratereviewtitle,
			ratereviewdesc,
			rateAndReviewSubmitted,
		} = this.state;
		const { username, password, loginSubmitted, loginCheck } = this.state;
		const {
			firstname,
			lastname,
			email,
			telephone,
			regpassword,
			registrationSubmitted,
			forgotPasswordSubmitted,
			forgotpassemail,
		} = this.state;
		//const notifytoemail = this.props.userEmail;
		const isLoggedIn = this.props.isLoggedIn;

		const apiError = {
			color: "red",
			fontSize: "16px",
			background: "#fff9b0",
			padding: "0px 5px 2px 5px",
			fontWeight: "normal",
			textAlign: "center",
			border: "1px solid red",
			borderRadius: "3px",
		};

		const apiSuccess = {
			color: "#316403",
			fontSize: "16px",
			background: "#cbffb0",
			padding: "0px 5px 2px 5px",
			fontWeight: "normal",
			textAlign: "center",
			border: "1px solid #178603",
			borderRadius: "3px",
		};

		const upsellproductslidersettings = {
			//dots: true,
			infinite: true,
			autoplay: true,
			//speed: 3000,
			autoplaySpeed: 4000,
			//rows: 1,
			//slidesPerRow: 2,
			slidesToShow: 1,
			slidesToScroll: 1,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
		};

		//console.log(this.state.isObjetoAvailable);
		// const scriptCode = `<script>
		//     window.intercomSettings = {
		//     app_id: "puw23zdb"
		//     };
		//     </script>
		//   <script>
		//   (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/puw23zdb';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
		//   </script>`

		if (isProductsDetailsLoaded) {
			if (this.state.productDetailsResponseBoolean) {
				return (
					<>
						{this.state.productAlias ? (
							<Helmet>
								<title>{this.state.productAlias}</title>
							</Helmet>
						) : (
							<></>
						)}

						{this.state.productBrand === "samsung" ? (
							<Helmet>
								<script
									type="text/javascript"
									name="ocular-solution-widget"
									src="https://widget.ocularsolution.com/service/ocular/js/ocular-widget.js?v=1.1.1"
									code="0f5653743f3b7108ad27628d5bb3de0d6hI4JedK83RzOHsEI8ngs11dBXbLad9GykRSCdouV6mi2eQ0M9JAqtffHar"
									async
								></script>
							</Helmet>
						) : (
							<></>
						)}

						{this.state.isObjetoAvailable ? (
							this.state.objetoScript.map((c, index) => (
								<Helmet>
									<script key={index} {...c} />
								</Helmet>
							))
						) : (
							<></>
						)}

						<Helmet>
							{/* Criteo Category / Listing dataLayer */}
							<script>
								{`
                        var dataLayer = dataLayer || [];
                        dataLayer.push({
                            event: 'crto_productpage',
                            crto: {
                                email: "${this.props.userEmail}",
                                products: ['${this.state.productModelo}']
                            }
                        });
                    `}
							</script>
							{/* END Criteo Category / Listing dataLayer */}

							{/* Criteo GTM Tag */}
							<script
								type="text/javascript"
								src="//static.criteo.net/js/ld/ld.js"
								async="true"
							></script>

							<script>
								{`
                        window.criteo_q = window.criteo_q || [];
                        window.criteo_q.push(
                        { event: "setAccount", account: 65991 },
                        { event: "setEmail", email: "${this.props.userEmail}" },
                        { event: "setSiteType", type: "d" },
                        { event: "viewList", item: ['${this.state.productModelo}'] }
                        );
                    `}
							</script>
							{/* End Criteo GTM Tag */}

							<script>
								{`        
                        fbq('track', 'ViewContent', {
                            content_ids: ['${this.state.productModelo}'],
                            content_type: 'product',
                            value: ${this.state.price},
                            currency:'USD'
                        });
                    `}
							</script>

							<script>
								{`
                        ga("ec:addImpression", {
                            "id": "${this.state.productModelo}",
                            "name": "${this.state.productMarca} ${this.state.productModelo}",
                            "price": ${this.state.price},
                            "brand": "${this.state.productMarca}",
                            "category": "${this.state.categoryName}",
                            
                            "position": 0,
                            "quantity": 1
                        });
                        ga("ec:setAction", "detail");
                        ga('send', 'pageview');
                    `}
							</script>

							<script type="application/ld+json">
								{`
                    {
                        "@context": "http://schema.org/",
                        "@type": "Product",
                        "name": "${this.state.productMarca} ${
									this.state.productModelo
								}",
                        "image": "${this.state.productImage}",
                        "description": "${this.escapeString(
							this.state.shortDescription
						)}",
                        "sku": "${this.state.productModelo}",
                        "mpn": "${this.state.productId}",
                        "brand": {
                            "@type": "Brand",
                            "name": "${this.state.productMarca}"
                        },
                        "review": {
                            "@type": "Review",
                            "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": 4.5,
                            "bestRating": 5
                            },
                            "author": {
                            "@type": "Person",
                            "name": "Neel"
                            }
                        },
                        "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": 4.5,
                            "reviewCount": 91
                        },
                        "offers": {
                            "@type": "Offer",
                            "url":"https://www.evisionstore.com/producto/${
								this.state.productModelo
							}",
                            "priceCurrency": "USD",
                            "price": ${this.state.price},
                            "itemCondition": "https://schema.org/NewCondition",
                            "availability": "${this.state.schemVal}"
                        }
                    }
                    `}
							</script>
						</Helmet>

						<div
							className={`loader-container ${this.state.productAttributeLoader}`}
						>
							<div className="loader" />
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 asd">
							<div className="exzoom" id="exzoom">
								<div className="exzoom_img_box">
									<div className="exzoom_img_ul">
										{(() => {
											if (
												this.state.productMultipleImages
													.length === 0
											) {
												return (
													<>
														{(() => {
															if (
																this.state
																	.productMediumImage
															) {
																return (
																	<InnerImageZoom
																		src={
																			this
																				.state
																				.productMediumImage
																		}
																		zoomSrc={
																			this
																				.state
																				.productImage
																		}
																	/>
																);
															} else {
																return (
																	<InnerImageZoom
																		src={
																			this
																				.state
																				.productImage
																		}
																		zoomSrc={
																			this
																				.state
																				.productImage
																		}
																	/>
																);
															}
														})()}
														{/* <SideBySideMagnifier
                                                className="input-position"
                                                imageSrc="https://adamrisberg.github.io/react-image-magnifiers/4700d4cb26b14563be996aa5f0c53ca2.jpg"
                                                imageAlt="Example"
                                                style={{ width: "1600px" }}
                                                largeImageSrc="https://adamrisberg.github.io/react-image-magnifiers/4700d4cb26b14563be996aa5f0c53ca2.jpg"
                                                alwaysInPlace={false}
                                                overlayOpacity={0.6}
                                                switchSides={false}
                                                zoomPosition="left"
                                                inPlaceMinBreakpoint={641}
                                                fillAvailableSpace={false}
                                                fillAlignTop={false}
                                                fillGapTop={0}
                                                fillGapRight={10}
                                                fillGapBottom={10}
                                                fillGapLeft={10}
                                                zoomContainerBorder="5px solid #ccc"
                                                zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
                                            />
                                            <ReactImageMagnify {...{
                                                smallImage: {
                                                    alt: '',
                                                    isFluidWidth: true,
                                                    src: this.state.productImage,
                                                },
                                                largeImage: {
                                                    src: this.state.productImage,
                                                    width: 1000,
                                                    height: 1000
                                                },
                                                enlargedImagePortalId: 'portal',
                                                enlargedImageContainerDimensions:{width: '150%', height: '100%'},
                                                isHintEnabled: true,
                                                shouldHideHintAfterFirstActivation: false
                                            }} />*/}
														{/* https://medium.com/how-to-react/create-image-magnifying-effect-in-reactjs-9823702bcdfd<img src={this.state.productImage} alt="" /> */}
													</>
												);
											} else {
												return (
													<Carousel
														autoPlay={false}
														showArrows={false}
														showDot={false}
														selectedItem={
															this.state.selItemId
														}
														onChange={() =>
															this.setState({
																selItemId: false,
															})
														}
													>
														{productMultipleImages.map(
															(
																thumbimage,
																index
															) => (
																<div
																	key={index}
																>
																	{/*<ReactImageMagnify {...{
                                                        smallImage: {
                                                            alt: '',
                                                            isFluidWidth: true,
                                                            src: thumbimage.image_name,
                                                        },
                                                        largeImage: {
                                                            src: thumbimage.image_name,
                                                            width: 1000,
                                                            height: 1000
                                                        },
                                                        enlargedImagePortalId: 'portal',
                                                        enlargedImageContainerDimensions:{width: '150%', height: '100%'},
                                                        isHintEnabled: true,
                                                        shouldHideHintAfterFirstActivation: false
                                                    }} /> */}
																	<img
																		src={
																			thumbimage.image_name
																		}
																		alt=""
																	/>
																</div>
															)
														)}
													</Carousel>
												);
											}
										})()}
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div
								className="fluid__instructions"
								style={{ position: "relative" }}
							>
								<div id="portal" className="portal"></div>
							</div>
							<div className="details-container">
								<h2
									dangerouslySetInnerHTML={{
										__html: this.state.productName,
									}}
								/>
								<span>{this.state.productModelo}</span>
								{/* <h3>{this.state.shortDescription}</h3> */}
								<div className="link">
									<Link
										to={`/categorias/${this.state.categorySlug}`}
									>
										Ver más de {this.state.categoryName}
									</Link>
									&nbsp;&nbsp;|&nbsp;&nbsp;
									{/* <Link
                    to={`/product-category/${this.state.categoryId}/${this.state.productBrand}`}
                  >
                    Ver más de {this.Capitalize(this.state.productBrand)}
                  </Link>
                  &nbsp;&nbsp;|&nbsp;&nbsp; */}
									{(() => {
										if (this.state.productRating) {
											return (
												<Link
													to="#"
													onClick={this.handleOnClick}
												>
													{parseInt(
														this.state.productRating
													)}{" "}
													Calificaciones y revisión
												</Link>
											);
										} else {
											return (
												<Link
													to="#"
													onClick={this.handleOnClick}
												>
													Calificaciones y revisión
												</Link>
											);
										}
									})()}
								</div>
								{/*- STATIC SECTION FOR CHECKING START -*/}
								{/* <div className="review-rating">
                  <div className="star">
                    {this.starRatings(this.state.productRating)}
                  </div>
                  <p>4.5 | 154 reviews</p>
                </div> */}
								{/*- STATIC SECTION FOR CHECKING ENDS -*/}
								<div>
									<button
										onClick={this.openServiceCenterPop}
										className="fillbtn"
									>
										<i
											className="fa fa-map-marker"
											aria-hidden="true"
										></i>{" "}
										Centro de servicio
									</button>
								</div>
								{(() => {
									if (this.state.productRating) {
										return (
											<div className="review-rating">
												<div className="star">
													{this.starRatings(
														this.state.productRating
													)}
												</div>
												<p>
													{this.state.productRating} |{" "}
													{
														this.state
															.totalCustomerReviews
													}{" "}
													reviews
												</p>
											</div>
											// <span className="cust-star-rating" style={{float:'left', marginBottom: '6px', marginTop: '-14px', color:'rgb(255, 185, 0)'}}>
											//     {this.starRatings(this.state.productRating)}
											// </span>
										);
									}
								})()}

								{(() => {
									if (this.state.price === "0.00") {
										return <></>;
									} else {
										if (this.state.isAllowedBacCredomatic) {
											const product_price = parseFloat(
												this.state.price
											);
											const tax_amount =
												product_price * (7 / 100);
											const pay_price = parseFloat(
												product_price + tax_amount
											);
											const per_month_price =
												pay_price / 12;
											return (
												<>
													{this.state.regularPrice ? (
														<div className="regular_price">
															<span
																style={{
																	textDecoration:
																		"line-through",
																}}
															>
																${" "}
																{
																	this.state
																		.regularPrice
																}
															</span>
														</div>
													) : (
														<></>
													)}
													<div className="price-tag">
														<div className="price">
															{
																this.state
																	.showPrice
															}

															{/* {this.state.regularPrice ? (
                                                <span className="save_price">You Save ${this.financial(this.state.regularPrice-this.state.price)}</span>
                                            ) : (
                                                <></>
                                            )} */}
														</div>
														<span className="price-tag-image">
															<img
																src={`${process.env.PUBLIC_URL}/images/bac-credomatic.jpg`}
																className="bac_credometic_img"
																alt="Bac Credomatic"
															/>
														</span>
													</div>
													<div className="bac_cred_section">
														{this.state
															.bacCredomaticOptions
															.length > 0 ? (
															this.state.bacCredomaticOptions.map(
																(option) => (
																	<>
																		<div
																			className="bac_credometic_span"
																			style={{
																				display:
																					"inline-block",
																				width: "auto",
																				marginBottom:
																					"10px",
																			}}
																		>
																			{
																				option
																			}{" "}
																			cuotas
																			de{" "}
																			{
																				this
																					.state
																					.currency
																			}{" "}
																			{this.financial(
																				pay_price /
																					option
																			)}{" "}
																			cada
																			una
																		</div>
																		<br />
																	</>
																)
															)
														) : (
															<>
																<div
																	className="bac_credometic_span"
																	style={{
																		display:
																			"inline-block",
																		width: "auto",
																		marginBottom:
																			"10px",
																	}}
																>
																	12 cuotas de{" "}
																	{
																		this
																			.state
																			.currency
																	}{" "}
																	{this.financial(
																		per_month_price
																	)}{" "}
																	cada una
																</div>
																<br />
															</>
														)}
													</div>
												</>
											);
										} else {
											return (
												<>
													{this.state.regularPrice ? (
														<div className="regular_price">
															<span
																style={{
																	textDecoration:
																		"line-through",
																}}
															>
																${" "}
																{
																	this.state
																		.regularPrice
																}
															</span>
														</div>
													) : (
														<></>
													)}
													<div className="price">
														{this.state.showPrice}
													</div>
												</>
											);
										}
									}
								})()}

								{productAttributes.length !== 0 ? (
									<>
										<div className="attributeSection">
											{productAttributes.map(
												(attributes, index2) => {
													return attributes.attribute_slug !==
														"color" ? (
														<div
															className="attrSizeDiv"
															key={index2}
														>
															<h4>
																{
																	attributes.attribute_name
																}
															</h4>
															{attributes
																.attribute_terms_arr
																.length !==
															0 ? (
																attributes.attribute_terms_arr.map(
																	(
																		terms,
																		index3
																	) => {
																		return terms.is_term_selected ? (
																			<div
																				className="sizeAttributes selected"
																				onClick={() =>
																					this.handleVariation(
																						attributes.attribute_id,
																						terms.term_id,
																						terms.term_slug
																					)
																				}
																				key={
																					index3
																				}
																			>
																				{
																					terms.term_name
																				}
																			</div>
																		) : (
																			<div
																				className="attrsizeradio"
																				key={
																					index3
																				}
																			>
																				<input
																					id={
																						attributes.attribute_slug +
																						"-" +
																						terms.term_id
																					}
																					type="radio"
																					name={
																						attributes.attribute_slug
																					}
																					value={
																						terms.term_slug
																					}
																					onChange={(
																						e
																					) =>
																						this.handleVariation(
																							attributes.attribute_id,
																							terms.term_id,
																							terms.term_slug
																						)
																					}
																				/>
																				<label
																					htmlFor={
																						attributes.attribute_slug +
																						"-" +
																						terms.term_id
																					}
																					className="sizeAttributes"
																				>
																					{
																						terms.term_name
																					}
																				</label>
																			</div>
																		);
																	}
																)
															) : (
																<></>
															)}
														</div>
													) : (
														<div
															className="attrColorDiv"
															key={index2}
														>
															<h4>
																{
																	attributes.attribute_name
																}
															</h4>
															{attributes
																.attribute_terms_arr
																.length !==
															0 ? (
																attributes.attribute_terms_arr.map(
																	(
																		terms,
																		index3
																	) => {
																		return terms.is_term_selected ? (
																			<div
																				className="colorAttributes selected"
																				onClick={() =>
																					this.handleVariation(
																						attributes.attribute_id,
																						terms.term_id,
																						terms.term_slug
																					)
																				}
																				key={
																					index3
																				}
																			>
																				<img
																					src={
																						terms.term_image
																					}
																					alt="attribute"
																				/>
																				<span>
																					{
																						terms.term_name
																					}
																				</span>
																			</div>
																		) : terms.term_image !==
																		  "" ? (
																			terms.allow_purchase !==
																			"0" ? (
																				<div
																					className="attrcolorradio"
																					key={
																						index3
																					}
																				>
																					<input
																						id={
																							attributes.attribute_slug +
																							"-" +
																							terms.term_id
																						}
																						type="radio"
																						name={
																							attributes.attribute_slug
																						}
																						value={
																							terms.term_slug
																						}
																						onChange={(
																							e
																						) =>
																							this.handleVariation(
																								attributes.attribute_id,
																								terms.term_id,
																								terms.term_slug
																							)
																						}
																					/>
																					<label
																						htmlFor={
																							attributes.attribute_slug +
																							"-" +
																							terms.term_id
																						}
																						className="colorAttributes"
																						key={
																							index3
																						}
																					>
																						<img
																							src={
																								terms.term_image
																							}
																							alt="attribute"
																						/>
																						<span>
																							{
																								terms.term_name
																							}
																						</span>
																					</label>
																				</div>
																			) : (
																				<div
																					className="attrcolorradio"
																					key={
																						index3
																					}
																				>
																					<input
																						id={
																							attributes.attribute_slug +
																							"-" +
																							terms.term_id
																						}
																						type="radio"
																						name={
																							attributes.attribute_slug
																						}
																						value={
																							terms.term_slug
																						}
																						onChange={(
																							e
																						) =>
																							this.handleVariation(
																								attributes.attribute_id,
																								terms.term_id,
																								terms.term_slug
																							)
																						}
																						disabled={
																							true
																						}
																					/>
																					<label
																						htmlFor={
																							attributes.attribute_slug +
																							"-" +
																							terms.term_id
																						}
																						className="colorAttributes"
																						key={
																							index3
																						}
																						style={{
																							opacity:
																								"0.5",
																							cursor: "not-allowed",
																						}}
																					>
																						<img
																							src={
																								terms.term_image
																							}
																							alt="attribute"
																						/>
																						<span>
																							{
																								terms.term_name
																							}
																						</span>
																					</label>
																				</div>
																			)
																		) : terms.term_code !==
																		  "" ? (
																			terms.allow_purchase !==
																			"0" ? (
																				<div
																					className="attrcolorradio"
																					key={
																						index3
																					}
																				>
																					<input
																						id={
																							attributes.attribute_slug +
																							"-" +
																							terms.term_id
																						}
																						type="radio"
																						name={
																							attributes.attribute_slug
																						}
																						value={
																							terms.term_slug
																						}
																						onChange={(
																							e
																						) =>
																							this.handleVariation(
																								attributes.attribute_id,
																								terms.term_id,
																								terms.term_slug
																							)
																						}
																					/>
																					<label
																						htmlFor={
																							attributes.attribute_slug +
																							"-" +
																							terms.term_id
																						}
																						className="sizeAttributes"
																						style={{
																							backgroundColor:
																								terms.term_code,
																							color: "#fff",
																						}}
																					>
																						{
																							terms.term_name
																						}
																					</label>
																				</div>
																			) : (
																				<div
																					className="attrcolorradio"
																					key={
																						index3
																					}
																				>
																					<input
																						id={
																							attributes.attribute_slug +
																							"-" +
																							terms.term_id
																						}
																						type="radio"
																						name={
																							attributes.attribute_slug
																						}
																						value={
																							terms.term_slug
																						}
																						onChange={(
																							e
																						) =>
																							this.handleVariation(
																								attributes.attribute_id,
																								terms.term_id,
																								terms.term_slug
																							)
																						}
																						disabled={
																							true
																						}
																					/>
																					<label
																						htmlFor={
																							attributes.attribute_slug +
																							"-" +
																							terms.term_id
																						}
																						className="sizeAttributes"
																						style={{
																							backgroundColor:
																								terms.term_code,
																							color: "#fff",
																							opacity:
																								"0.5",
																							cursor: "not-allowed",
																						}}
																					>
																						{
																							terms.term_name
																						}
																					</label>
																				</div>
																			)
																		) : (
																			<div
																				className="attrcolorradio"
																				key={
																					index3
																				}
																			>
																				<input
																					id={
																						attributes.attribute_slug +
																						"-" +
																						terms.term_id
																					}
																					type="radio"
																					name={
																						attributes.attribute_slug
																					}
																					value={
																						terms.term_slug
																					}
																					onChange={(
																						e
																					) =>
																						this.handleVariation(
																							attributes.attribute_id,
																							terms.term_id,
																							terms.term_slug
																						)
																					}
																				/>
																				<label
																					htmlFor={
																						attributes.attribute_slug +
																						"-" +
																						terms.term_id
																					}
																					className="sizeAttributes"
																				>
																					{
																						terms.term_name
																					}
																				</label>
																			</div>
																		);
																	}
																)
															) : (
																<></>
															)}
														</div>
													);
												}
											)}

											{this.state.productDescripcion ? (
												<div
													className="descSec"
													dangerouslySetInnerHTML={{
														__html: this.state
															.productDescripcion,
													}}
												></div>
											) : (
												<></>
											)}
										</div>
									</>
								) : (
									<></>
								)}

								<div className="bottom-actions">
									{this.state.addToCartOption !== 0 ? (
										this.state.quantity > 0 ? (
											<>
												<div className="qty">
													<a
														href="javascript:void(0)"
														class="count-btn count-btn__left"
														onClick={
															this.handleDecrement
														}
													>
														-
													</a>
													<input
														className={
															"quantity" +
															(!this.state
																.isQuanValid
																? " has-error"
																: "")
														}
														type="text"
														name="productQuantity"
														value={productQuantity}
														onChange={
															this
																.handleOnQuantityChange
														}
														maxLength="4"
														style={{
															padding: "5px",
														}}
													/>
													<a
														href="javascript:void(0)"
														class="count-btn count-btn__right"
														onClick={
															this.handleIncrement
														}
													>
														+
													</a>
												</div>
												{/* <span
                          className={
                            this.state.addToCartSuccessMsg ? "" : "hidden"
                          }
                          style={{
                            color: "green",
                            fontWeight: "normal",
                            fontSize: "16px",
                            marginBottom: "10px",
                            border: "1px solid green",
                            padding: "5px 10px",
                            background: "#daffe0",
                            float: "left",
                            textAlign: "center",
                          }}
                        >
                          <i className="fa fa-check" aria-hidden="true"></i>{" "}
                          {this.state.addToCartSuccessMsg}
                        </span> */}
												{this.state
													.isVariationDisplay ? (
													this.state
														.selectedAttributesCount ===
														this.state
															.attributesCount ||
													this.state
														.selectedAttributesCount >=
														this.state
															.attributesCount ? (
														<button
															onClick={() =>
																this.handleOnAddToCart(
																	this.state
																		.productId,
																	this.state
																		.productName,
																	this.state
																		.productImage,
																	this.state
																		.price,
																	this.state
																		.productMarca,
																	this.state
																		.productModelo,
																	this.state
																		.categoryName,
																	this.state
																		.isAllowedBacCredomatic
																)
															}
															disabled={
																this.state
																	.addToCartButtonDisabled
															}
														>
															{this.state
																.addToCartButtonDisabled
																? "Añadiendo"
																: "Agregar al carrito"}
															&nbsp;
															<i
																className={`fa fa-refresh fa-spin ${this.state.addToCartButtonnLoader}`}
																aria-hidden="true"
															></i>
															{/* <i className={`fa fa-shopping-cart ${ this.state.addToCartButtonnCart }`} aria-hidden="true"></i>  */}
															<img
																src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`}
																alt="cart icon"
															/>
														</button>
													) : (
														<>
															<p
																style={{
																	marginTop:
																		"10px",
																}}
															>
																<span className="variation_error_msg">
																	To buy this
																	product,
																	please
																	select the
																	product
																	variations.
																</span>
															</p>
															<button
																className="btn btn-primary disabled"
																disabled="disabled"
															>
																<i
																	className="fa fa-shopping-cart"
																	aria-hidden="true"
																></i>{" "}
																Agregar al
																carrito
															</button>
														</>
													)
												) : (
													<button
														onClick={() =>
															this.handleOnAddToCart(
																this.state
																	.productId,
																this.state
																	.productName,
																this.state
																	.productImage,
																this.state
																	.price,
																this.state
																	.productMarca,
																this.state
																	.productModelo,
																this.state
																	.categoryName,
																this.state
																	.isAllowedBacCredomatic
															)
														}
														disabled={
															this.state
																.addToCartButtonDisabled
														}
													>
														{this.state
															.addToCartButtonDisabled
															? "Añadiendo"
															: "Agregar al carrito"}
														&nbsp;
														<i
															className={`fa fa-refresh fa-spin ${this.state.addToCartButtonnLoader}`}
															aria-hidden="true"
														></i>
														{/* <i className={`fa fa-shopping-cart ${ this.state.addToCartButtonnCart }`} aria-hidden="true"></i>  */}
														<img
															src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`}
															alt="cart icon"
														/>
													</button>
												)}
											</>
										) : (
											<>
												<span className="outofstock-page-details">
													<svg
														width="18px"
														height="18px"
														viewBox="0 0 24 24"
														fill="none"
														stroke="#de1d1d"
													>
														<g strokeWidth="0" />
														<g
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<g>
															{" "}
															<path
																stroke="#de1d1d"
																strokeLinecap="round"
																strokeLinejoin="round"
																strokeWidth="2"
																d="M12 9v4m0 3v.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.068C2.842 18.4 3.808 20 5.313 20Z"
															/>{" "}
														</g>
													</svg>{" "}
													Producto fuera de Inventario
												</span>
												<button
													onClick={this.openNotifyPop}
													style={{
														margin: "10px 0 10px 0",
													}}
												>
													<i
														className="fa fa-bell"
														aria-hidden="true"
													></i>{" "}
													Recibir notificación sobre
													disponibilidad
												</button>
											</>
										)
									) : (
										<>
											<span className="outofstock-page-details">
												<svg
													width="18px"
													height="18px"
													viewBox="0 0 24 24"
													fill="none"
													stroke="#de1d1d"
												>
													<g strokeWidth="0" />
													<g
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<g>
														{" "}
														<path
															stroke="#de1d1d"
															strokeLinecap="round"
															strokeLinejoin="round"
															strokeWidth="2"
															d="M12 9v4m0 3v.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.068C2.842 18.4 3.808 20 5.313 20Z"
														/>{" "}
													</g>
												</svg>{" "}
												Producto fuera de Inventario
											</span>
											<button
												onClick={this.openNotifyPop}
												style={{
													margin: "10px 0 10px 0",
												}}
											>
												<i
													className="fa fa-bell"
													aria-hidden="true"
												></i>{" "}
												Recibir notificación sobre
												disponibilidad
											</button>
										</>
									)}

									{/* <div className="clear"></div> */}

									<a
										className="whatsapp"
										rel="noopener noreferrer"
										href={`https://api.whatsapp.com/send?phone=50764447679&text=Hola. Estoy interesado en este producto: https://www.evisionstore.com/producto/${this.state.productModel}`}
										target="_blank"
									>
										<i
											className="fa fa-whatsapp"
											aria-hidden="true"
										></i>
									</a>
								</div>
								{/* <a href="javascript:void(0)" className="buy-now">
                  Comprar ahora
                  <img
                    src={`${process.env.PUBLIC_URL}/images/new-images/icon/home-slide-arrow-next.svg`}
                    alt="arrow"
                  />
                </a> */}
							</div>
							<div className="upsells-container">
								{Array.isArray(upsellProducts) &&
								upsellProducts.length !== 0 ? (
									<div
										className="row"
										style={{ marginTop: "25px" }}
									>
										<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12">
											<h3
												className="upsell-title"
												style={{
													fontSize: "20px",
													fontWeight: 600,
													marginBottom: "10px",
												}}
											>
												Productos Recomendados
											</h3>
											{upsellProducts.length > 1 ? (
												<>
													<UpsellSlider
														{...upsellproductslidersettings}
													>
														{upsellProducts.map(
															(
																upproducts,
																index
															) => (
																<div
																	className="slide"
																	key={index}
																>
																	<div
																		className="upsell-product-item"
																		style={{
																			padding:
																				"5px",
																			width: "95%",
																		}}
																	>
																		<Link
																			to={`/producto/${upproducts.modelo}`}
																			style={{
																				textDecoration:
																					"none",
																				color: "#333",
																			}}
																		>
																			<figure>
																				<img
																					src={
																						upproducts.product_image
																					}
																					alt={
																						upproducts.marca
																					}
																					style={{
																						height: "120px",
																					}}
																				/>
																			</figure>
																		</Link>
																		<div className="heading-wrap">
																			<div className="price">
																				${" "}
																				{
																					upproducts.price
																				}
																			</div>
																			<h2>
																				<Link
																					to={`/producto/${upproducts.modelo}`}
																					style={{
																						textDecoration:
																							"none",
																						color: "#333",
																					}}
																				>
																					{
																						upproducts.product_name
																					}
																				</Link>
																			</h2>
																			{/* <p style={{fontSize:'12px'}}>{upproducts.short_desc ? upproducts.short_desc : "..."}</p> */}
																			{(() => {
																				if (
																					upproducts.addtocart_option ===
																					1
																				) {
																					if (
																						this
																							.state
																							.addToCartButton
																					) {
																						return (
																							<button
																								onClick={() =>
																									this.handleOnUpsellAddToCart(
																										upproducts.product_id,
																										upproducts.product_name,
																										upproducts.product_image,
																										upproducts.price,
																										"1",
																										upproducts.marca,
																										upproducts.modelo,
																										upproducts.category_name
																									)
																								}
																								disabled={
																									this
																										.state
																										.addToCartButtonDisabled
																								}
																							>
																								{(() => {
																									if (
																										this
																											.state
																											.clickedProductId ===
																											upproducts.product_id &&
																										this
																											.state
																											.addToCartUpsellButtonnLoader ===
																											""
																									) {
																										return (
																											<>
																												<i className="fa fa-refresh fa-spin"></i>{" "}
																												Agregar
																												al
																												carrito
																											</>
																										);
																									} else {
																										return (
																											<>
																												<i className="fa fa-shopping-cart"></i>{" "}
																												Agregar
																												al
																												carrito
																											</>
																										);
																									}
																								})()}
																							</button>
																						);
																					} else if (
																						!this
																							.state
																							.addToCartButton &&
																						this
																							.state
																							.clickedProductId ===
																							upproducts.product_id
																					) {
																						return (
																							<Link
																								to="/cart"
																								className="btn btn-default upprodlink"
																							>
																								Ver
																								producto{" "}
																								<i
																									className="fa fa-long-arrow-right"
																									aria-hidden="true"
																								></i>
																							</Link>
																						);
																					} else if (
																						!this
																							.state
																							.addToCartButton &&
																						this
																							.state
																							.clickedProductId !==
																							upproducts.product_id
																					) {
																						return (
																							<button
																								onClick={() =>
																									this.handleOnUpsellAddToCart(
																										upproducts.product_id,
																										upproducts.product_name,
																										upproducts.product_image,
																										upproducts.price,
																										"1",
																										upproducts.marca,
																										upproducts.modelo,
																										upproducts.category_name
																									)
																								}
																							>
																								<i className="fa fa-shopping-cart"></i>{" "}
																								Agregar
																								al
																								carrito
																							</button>
																						);
																					} else {
																						return (
																							<Link
																								to={`/producto/${upproducts.modelo}`}
																								className="btn btn-default upprodlink"
																							>
																								Ver
																								producto{" "}
																								<i
																									className="fa fa-long-arrow-right"
																									aria-hidden="true"
																								></i>
																							</Link>
																						);
																					}
																				} else {
																					return (
																						<Link
																							to={`/producto/${upproducts.modelo}`}
																							className="btn btn-default upprodlink"
																						>
																							Ver
																							producto{" "}
																							<i
																								className="fa fa-long-arrow-right"
																								aria-hidden="true"
																							></i>
																						</Link>
																					);
																				}
																			})()}
																		</div>
																	</div>
																</div>
															)
														)}
													</UpsellSlider>
												</>
											) : (
												<>
													{upsellProducts.map(
														(upproducts, index) => (
															<div
																className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
																key={index}
															>
																<div
																	className="upsell-product-item"
																	style={{
																		padding:
																			"5px",
																		marginLeft:
																			"-10px",
																	}}
																>
																	<Link
																		to={`/producto/${upproducts.modelo}`}
																		style={{
																			textDecoration:
																				"none",
																			color: "#333",
																		}}
																	>
																		<figure
																			style={{
																				marginBottom:
																					"5px",
																				padding:
																					"10px 30px",
																			}}
																		>
																			<img
																				src={
																					upproducts.product_image
																				}
																				alt={
																					upproducts.marca
																				}
																				style={{
																					height: "120px",
																				}}
																			/>
																		</figure>
																	</Link>
																	<div className="heading-wrap">
																		<div
																			className="price"
																			style={{
																				fontSize:
																					"16px",
																				marginBottom:
																					"5px",
																			}}
																		>
																			${" "}
																			{
																				upproducts.price
																			}
																		</div>
																		<h2
																			style={{
																				fontSize:
																					"11px",
																				minHeight:
																					"30px",
																			}}
																		>
																			<Link
																				to={`/producto/${upproducts.modelo}`}
																				style={{
																					textDecoration:
																						"none",
																					color: "#333",
																				}}
																			>
																				{
																					upproducts.product_name
																				}
																			</Link>
																		</h2>
																		{/* <p style={{fontSize:'12px'}}>{upproducts.short_desc ? upproducts.short_desc : "..."}</p> */}
																		{(() => {
																			if (
																				upproducts.addtocart_option ===
																				1
																			) {
																				if (
																					this
																						.state
																						.addToCartButton
																				) {
																					return (
																						<button
																							onClick={() =>
																								this.handleOnUpsellAddToCart(
																									upproducts.product_id,
																									upproducts.product_name,
																									upproducts.product_image,
																									upproducts.price,
																									"1"
																								)
																							}
																							disabled={
																								this
																									.state
																									.addToCartButtonDisabled
																							}
																							style={{
																								marginBottom:
																									"8px",
																								width: "92%",
																								fontSize:
																									"12px",
																								padding:
																									"7px 5px",
																							}}
																						>
																							{(() => {
																								if (
																									this
																										.state
																										.clickedProductId ===
																										upproducts.product_id &&
																									this
																										.state
																										.addToCartUpsellButtonnLoader ===
																										""
																								) {
																									return (
																										<>
																											<i className="fa fa-refresh fa-spin"></i>{" "}
																											Agregar
																											al
																											carrito
																										</>
																									);
																								} else {
																									return (
																										<>
																											<i className="fa fa-shopping-cart"></i>{" "}
																											Agregar
																											al
																											carrito
																										</>
																									);
																								}
																							})()}
																						</button>
																					);
																				} else if (
																					!this
																						.state
																						.addToCartButton &&
																					this
																						.state
																						.clickedProductId ===
																						upproducts.product_id
																				) {
																					return (
																						<Link
																							to="/cart"
																							className="btn btn-default"
																							style={{
																								marginBottom:
																									"8px",
																								width: "67%",
																								fontSize:
																									"12px",
																								padding:
																									"7px 5px",
																							}}
																						>
																							Ver
																							producto{" "}
																							<i
																								className="fa fa-long-arrow-right"
																								aria-hidden="true"
																							></i>
																						</Link>
																					);
																				} else if (
																					!this
																						.state
																						.addToCartButton &&
																					this
																						.state
																						.clickedProductId !==
																						upproducts.product_id
																				) {
																					return (
																						<button
																							onClick={() =>
																								this.handleOnUpsellAddToCart(
																									upproducts.product_id,
																									upproducts.product_name,
																									upproducts.product_image,
																									upproducts.price,
																									"1"
																								)
																							}
																							style={{
																								marginBottom:
																									"8px",
																								width: "92%",
																								fontSize:
																									"12px",
																								padding:
																									"7px 5px",
																							}}
																						>
																							<i className="fa fa-shopping-cart"></i>{" "}
																							Agregar
																							al
																							carrito
																						</button>
																					);
																				} else {
																					return (
																						<Link
																							to={`/producto/${upproducts.modelo}`}
																							className="btn btn-default"
																							style={{
																								marginBottom:
																									"8px",
																								width: "67%",
																								fontSize:
																									"12px",
																								padding:
																									"7px 5px",
																							}}
																						>
																							Ver
																							producto{" "}
																							<i
																								className="fa fa-long-arrow-right"
																								aria-hidden="true"
																							></i>
																						</Link>
																					);
																				}
																			} else {
																				return (
																					<Link
																						to={`/producto/${upproducts.modelo}`}
																						className="btn btn-default"
																						style={{
																							marginBottom:
																								"8px",
																							width: "67%",
																							fontSize:
																								"12px",
																							padding:
																								"7px 5px",
																						}}
																					>
																						Ver
																						producto{" "}
																						<i
																							className="fa fa-long-arrow-right"
																							aria-hidden="true"
																						></i>
																					</Link>
																				);
																			}
																		})()}
																	</div>
																</div>
															</div>
														)
													)}
												</>
											)}
										</div>
									</div>
								) : (
									<></>
								)}
							</div>
						</div>

						{productMetas.length !== 0 ? (
							<div className="col-xl-12">
								<div className="card-desc m-t-20 m-b-30">
									<div className="card-block">
										<div className="row">
											{productMetas.map(
												(metas, index6) => {
													return (
														<div className="col-xl-3 col-md-6">
															<h6 className="m-t-20">
																{
																	metas.meta_title
																}
															</h6>
															<h2 className="hrt m-b-20"></h2>
															<h5 className="f-w-700">
																{
																	metas.meta_details
																}
															</h5>
														</div>
													);
												}
											)}
										</div>
									</div>
								</div>
							</div>
						) : (
							<></>
						)}

						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="details-container details-container-info">
								<div
									dangerouslySetInnerHTML={{
										__html: this.state.descripcion,
									}}
								></div>
								{this.state.isObjetoAvailable ? (
									<div
										className="contenido_indexado"
										id="contenidoIndexado"
									></div>
								) : (
									<></>
								)}
								{this.state.isIframeObjetoAvailable ? (
									<div
										dangerouslySetInnerHTML={{
											__html: this.state
												.iframeObjetoScript,
										}}
									></div>
								) : (
									<></>
								)}
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							{/* <div className="details-container details-container-info">
                <div className="descripcions">
                  <h3>Calidad sin complejidad</h3>
                  <p>
                    Ideal para usuarios de dispositivos móviles que desean
                    llevar su fotografía al siguiente paso, la cámara EOS Rebel
                    T7 combina funciones fantásticas con un funcionamiento fácil
                    de usar para obtener imágenes de alta calidad que estará
                    orgulloso de compartir. Aproveche la potencia del gran
                    sensor CMOS de 24,1 megapíxeles de la EOS Rebel T7 para
                    liberar su gran potencial. Comparta recuerdos con sus amigos
                    y familiares de forma rápida y sencilla mediante la
                    tecnología Wi-Fi y NFC integradas. Con el rápido sistema de
                    enfoque automático de la EOS Rebel T7, puede capturar cada
                    momento tal como sucede y ver cada toma a través del
                    brillante visor óptico. Una gama de funciones adicionales,
                    que incluyen una pantalla LCD grande, grabación de video
                    Full HD y una guía de funciones incorporada, lo ayudan a
                    capturar fotos y videos llamativos
                  </p>
                </div>
              </div> */}
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="details-container details-container-info">
								<div className="descripcions">
									<div className="review-tittle">
										<h3>Calificaciones y reseñas</h3>
										<div className="review-count">
											<img
												src={`${process.env.PUBLIC_URL}/images/new-images/icon/star.svg`}
												alt="star icon"
											/>
											{/* Static Review Count section Start */}
											{this.state.productRating} |{" "}
											{this.state.totalCustomerReviews}{" "}
											reviews
											{/* Static Review Count section Ends */}
										</div>
									</div>
									{/* Static Review section Start */}
									{/* <div className="review-box">
                    <div className="review-box__tittlename">
                      <div className="review-box__imagearea">
                        <span>
                          <img src={`${process.env.PUBLIC_URL}/images/new-images/static-review-image.png`} alt="reviewer image" />
                        </span>
                        <div className="namearea">
                            <h4>Scarlett Johansson</h4>
                            <span>21 Oct, 2006</span>
                        </div>
                      </div>
                      <div className="review-box__rating">
                        <div className="stararea">
                          {this.starRatings(this.state.productRating)}
                        </div>
                        4.5
                      </div>
                    </div>
                    <div className="review-box__contentarea">
                      <h4>Very Good Product</h4>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div>
                  </div> */}
									{/* Static Review section Ends */}
								</div>
								{(() => {
									if (this.state.productRating > 0) {
										return (
											<>
												<div className="row">
													<div className="col-md-12">
														{this.state.customerReviews.map(
															(
																reviews,
																index
															) => (
																<div
																	className="review-box"
																	key={index}
																>
																	<div className="review-box__tittlename">
																		<div className="review-box__imagearea">
																			<span>
																				<img
																					src={`${process.env.PUBLIC_URL}/images/new-images/review-avatar.png`}
																					alt="reviewer image"
																				/>
																			</span>
																			<div className="namearea">
																				<h4>
																					{
																						reviews.customer_name
																					}
																				</h4>
																				<span>
																					{
																						reviews.customer_review_time
																					}
																				</span>
																			</div>
																		</div>
																		<div className="review-box__rating">
																			<div className="stararea">
																				{this.starRatings(
																					reviews.customer_ratings
																				)}
																			</div>
																			{
																				reviews.customer_ratings
																			}
																		</div>
																	</div>
																	<div className="review-box__contentarea">
																		<h4>
																			{
																				reviews.title
																			}
																		</h4>
																		<p>
																			{
																				reviews.comments
																			}
																		</p>
																	</div>
																</div>
																// <div className="reviews-section" key={index}>
																//   <p style={{ marginBottom: "5px" }}>
																//     <img
																//       src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-login.svg`}
																//       className="customer_prof"
																//       alt=""
																//       style={{ marginRight: "10px" }}
																//     />
																//     <span>{reviews.customer_name}</span>
																//   </p>
																//   <p
																//     className="stars-ratings"
																//     style={{
																//       color: "#ffb900",
																//       marginBottom: "0px",
																//     }}
																//   >
																//     {this.starRatings(reviews.customer_ratings)}
																//   </p>
																//   <p>
																//     <span style={{ float: "left", width: "100%" }}>
																//       Reviewed on {reviews.customer_review_time}
																//     </span>
																//     <span
																//       style={{
																//         fontWeight: "500",
																//         float: "left",
																//         width: "100%",
																//       }}
																//     >
																//       {reviews.customer_review_title}
																//     </span>
																//     <span style={{ float: "left", width: "100%" }}>
																//       {reviews.customer_review_comments}
																//     </span>
																//   </p>
																// </div>
															)
														)}
													</div>
												</div>

												<div
													className="col-md-6"
													style={{
														paddingLeft: "0px",
													}}
												>
													{/* <h2
                            style={{ marginBottom: "10px" }}
                            ref={this.rateReviewDivFocus}
                            id="ratesec"
                          >
                            Opiniones de clientes
                          </h2>

                          <div
                            className="col-md-12"
                            style={{
                              paddingLeft: "0px",
                              margin: "5px 0px 10px",
                            }}
                          >
                            <div
                              className="stars-ratings"
                              style={{
                                color: "#ffb900",
                                float: "left",
                                marginRight: "10px",
                              }}
                            >
                              {this.starRatings(this.state.productRating)}
                            </div>
                            <span>
                              {this.financialSingle(this.state.productRating)}{" "}
                              out of 5 star
                            </span>
                          </div>

                          <div
                            className="col-md-12"
                            style={{
                              paddingLeft: "0px",
                              margin: "0px 0px 12px 0px",
                            }}
                          >
                            <span style={{ fontWeight: "500" }}>
                              {this.state.totalCustomerReviews} customer reviews
                            </span>
                          </div>
                          <div
                            className="col-md-12"
                            style={{ paddingLeft: "0px" }}
                          >
                            <div style={{ float: "left", marginRight: "10px" }}>
                              <i
                                className="fa fa-star"
                                style={{ color: "rgb(252 154 9)" }}
                              />{" "}
                              5 star
                            </div>
                            <div className="progress skill-bar">
                              <div
                                style={{
                                  width: `${this.state.fiveStarRatingPercentage}`,
                                }}
                                className="progress-bar-line progress-bar-warning"
                                role="progressbar"
                              ></div>
                            </div>
                            <div style={{ float: "left", marginLeft: "10px" }}>
                              {this.state.fiveStarRating} reviews
                            </div>
                          </div>
                          <div
                            className="col-md-12"
                            style={{ paddingLeft: "0px" }}
                          >
                            <div style={{ float: "left", marginRight: "10px" }}>
                              <i
                                className="fa fa-star"
                                style={{ color: "rgb(252 154 9)" }}
                              />{" "}
                              4 star
                            </div>
                            <div className="progress skill-bar">
                              <div
                                style={{
                                  width: `${this.state.fourStarRatingPercentage}`,
                                }}
                                className="progress-bar-line progress-bar-warning"
                                role="progressbar"
                              ></div>
                            </div>
                            <div style={{ float: "left", marginLeft: "10px" }}>
                              {this.state.fourStarRating} reviews
                            </div>
                          </div>
                          <div
                            className="col-md-12"
                            style={{ paddingLeft: "0px" }}
                          >
                            <div style={{ float: "left", marginRight: "10px" }}>
                              <i
                                className="fa fa-star"
                                style={{ color: "rgb(252 154 9)" }}
                              />{" "}
                              3 star
                            </div>
                            <div className="progress skill-bar">
                              <div
                                style={{
                                  width: `${this.state.threeStarRatingPercentage}`,
                                }}
                                className="progress-bar-line progress-bar-warning"
                                role="progressbar"
                              ></div>
                            </div>
                            <div style={{ float: "left", marginLeft: "10px" }}>
                              {this.state.threeStarRating} reviews
                            </div>
                          </div>
                          <div
                            className="col-md-12"
                            style={{ paddingLeft: "0px" }}
                          >
                            <div style={{ float: "left", marginRight: "10px" }}>
                              <i
                                className="fa fa-star"
                                style={{ color: "rgb(252 154 9)" }}
                              />{" "}
                              2 star
                            </div>
                            <div className="progress skill-bar">
                              <div
                                style={{
                                  width: `${this.state.twoStarRatingPercentage}`,
                                }}
                                className="progress-bar-line progress-bar-warning"
                                role="progressbar"
                              ></div>
                            </div>
                            <div style={{ float: "left", marginLeft: "10px" }}>
                              {this.state.twoStarRating} reviews
                            </div>
                          </div>
                          <div
                            className="col-md-12"
                            style={{ paddingLeft: "0px" }}
                          >
                            <div style={{ float: "left", marginRight: "10px" }}>
                              <i
                                className="fa fa-star"
                                style={{ color: "rgb(252 154 9)" }}
                              />{" "}
                              1 star
                            </div>
                            <div className="progress skill-bar">
                              <div
                                style={{
                                  width: `${this.state.oneStarRatingPercentage}`,
                                }}
                                className="progress-bar-line progress-bar-warning"
                                role="progressbar"
                              ></div>
                            </div>
                            <div style={{ float: "left", marginLeft: "10px" }}>
                              {this.state.oneStarRating} reviews
                            </div>
                          </div> */}

													<div
														className="col-md-12"
														style={{
															paddingLeft: "0px",
														}}
													>
														<hr />
														<h2
															style={{
																margin: "15px 0px 10px 0px",
																fontSize:
																	"25px",
															}}
														>
															Opina sobre este
															producto
														</h2>
														{isLoggedIn ? (
															<button
																onClick={
																	this
																		.handleOnClickToReview
																}
																style={{
																	padding:
																		"14px 35px",
																	fontSize:
																		"15px",
																}}
															>
																Escribir la
																reseña de un
																producto
															</button>
														) : (
															<button
																onClick={
																	this
																		.openLoginPop
																}
																style={{
																	padding:
																		"14px 35px",
																	fontSize:
																		"15px",
																}}
															>
																Escribir la
																reseña de un
																producto
															</button>
														)}
													</div>
												</div>
											</>
										);
									} else {
										return (
											<div className="col-md-12">
												{/* <h2
                          style={{
                            margin: "15px 0px 10px 0px",
                            fontSize: "18px",
                          }}
                        >
                          Opina sobre este producto
                        </h2> */}
												<div className="review-under-button">
													{/* Static Button for new design */}
													{/* <a href="javascript:void(0)" className="outlinebtn">
                            Ver toda la reseña
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                              <path d="M9.5 18C14.1944 18 18 14.1944 18 9.5C18 4.80558 14.1944 1 9.5 1C4.80558 1 1 4.80558 1 9.5C1 14.1944 4.80558 18 9.5 18Z" stroke="#0075B9" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M6.1001 9.5H12.9001H6.1001Z" fill="#0075B9"/>
                              <path d="M6.1001 9.5H12.9001" stroke="#0075B9" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M9.5 12.9001L12.9 9.5001L9.5 6.1001" stroke="#0075B9" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                          </a> */}
													{isLoggedIn ? (
														<button
															onClick={
																this
																	.handleOnClickToReview
															}
															className="fillbtn"
														>
															Escribir la reseña
															de un producto
															<svg
																width="19"
																height="19"
																viewBox="0 0 19 19"
																fill="none"
															>
																<path
																	d="M9.5 18C14.1944 18 18 14.1944 18 9.5C18 4.80558 14.1944 1 9.5 1C4.80558 1 1 4.80558 1 9.5C1 14.1944 4.80558 18 9.5 18Z"
																	stroke="white"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M6.1001 9.5H12.9001H6.1001Z"
																	fill="white"
																/>
																<path
																	d="M6.1001 9.5H12.9001"
																	stroke="white"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M9.5 12.9001L12.9 9.5001L9.5 6.1001"
																	stroke="white"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
														</button>
													) : (
														<button
															onClick={
																this
																	.openLoginPop
															}
															className="fillbtn"
														>
															Escribir la reseña
															de un producto
															<svg
																width="19"
																height="19"
																viewBox="0 0 19 19"
																fill="none"
															>
																<path
																	d="M9.5 18C14.1944 18 18 14.1944 18 9.5C18 4.80558 14.1944 1 9.5 1C4.80558 1 1 4.80558 1 9.5C1 14.1944 4.80558 18 9.5 18Z"
																	stroke="white"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M6.1001 9.5H12.9001H6.1001Z"
																	fill="white"
																/>
																<path
																	d="M6.1001 9.5H12.9001"
																	stroke="white"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M9.5 12.9001L12.9 9.5001L9.5 6.1001"
																	stroke="white"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
														</button>
													)}
												</div>
											</div>
										);
									}
								})()}

								{isLoggedIn ? (
									<div
										className={`review-submit-section ${this.state.reviewFormSection}`}
										ref={this.rateSubmitReviewDivFocus}
									>
										<p className="full-width">
											<span
												className="star-rating-title"
												style={{
													width: "100%",
													float: "left",
												}}
											>
												Sé el primero en calificar este
												producto
											</span>
											<span className="star-rating">
												<input
													id="star-5b"
													type="radio"
													name="rating"
													defaultValue={5}
													onClick={() =>
														this.handleOnRateAndReviewClick(
															5
														)
													}
												/>
												<label
													htmlFor="star-5b"
													title="5 stars"
												>
													<i
														className="fa fa-star"
														aria-hidden="true"
													/>
												</label>
												<input
													id="star-4b"
													type="radio"
													name="rating"
													defaultValue={4}
													onClick={() =>
														this.handleOnRateAndReviewClick(
															4
														)
													}
												/>
												<label
													htmlFor="star-4b"
													title="4 stars"
												>
													<i
														className="fa fa-star"
														aria-hidden="true"
													/>
												</label>
												<input
													id="star-3b"
													type="radio"
													name="rating"
													defaultValue={3}
													onClick={() =>
														this.handleOnRateAndReviewClick(
															3
														)
													}
												/>
												<label
													htmlFor="star-3b"
													title="3 stars"
												>
													<i
														className="fa fa-star"
														aria-hidden="true"
													/>
												</label>
												<input
													id="star-2b"
													type="radio"
													name="rating"
													defaultValue={2}
													onClick={() =>
														this.handleOnRateAndReviewClick(
															2
														)
													}
												/>
												<label
													htmlFor="star-2b"
													title="2 stars"
												>
													<i
														className="fa fa-star"
														aria-hidden="true"
													/>
												</label>
												<input
													id="star-1b"
													type="radio"
													name="rating"
													defaultValue={1}
													onClick={() =>
														this.handleOnRateAndReviewClick(
															1
														)
													}
												/>
												<label
													htmlFor="star-1b"
													title="1 star"
												>
													<i
														className="fa fa-star"
														aria-hidden="true"
													/>
												</label>
											</span>
											<span
												style={{
													color: "red",
													float: "left",
													width: "100%",
												}}
											>
												{
													this.state.errors[
														"customerRatedNumber"
													]
												}
											</span>
										</p>
										<Form.Group controlId="formGroupRateAndReviewTitle">
											<Form.Label>Título *</Form.Label>
											<Form.Control
												type="text"
												name="ratereviewtitle"
												value={ratereviewtitle}
												onChange={
													this
														.handleOnRateReviewInputChange
												}
												placeholder="Ingrese un título de revisión."
												className={
													"form-control" +
													(rateAndReviewSubmitted &&
													!ratereviewtitle
														? " has-error"
														: "")
												}
												maxLength="60"
											/>
											<span style={{ color: "red" }}>
												{
													this.state.errors[
														"ratereviewtitle"
													]
												}
											</span>
										</Form.Group>
										<Form.Group controlId="formGroupRateAndReviewDesc">
											<Form.Label>
												Comentario *
											</Form.Label>
											<Form.Control
												as="textarea"
												rows="3"
												name="ratereviewdesc"
												value={ratereviewdesc}
												onChange={
													this
														.handleOnRateReviewInputChange
												}
												className={
													"form-control" +
													(rateAndReviewSubmitted &&
													!ratereviewdesc
														? " has-error"
														: "")
												}
												maxLength="260"
											/>
											<span style={{ color: "red" }}>
												{
													this.state.errors[
														"ratereviewdesc"
													]
												}
											</span>
										</Form.Group>
										<button
											onClick={
												this.handleOnRateAndReviewSubmit
											}
										>
											<i
												className={`fa fa-refresh fa-spin ${this.state.ratereviewLoader}`}
												style={{ fontSize: "17px" }}
											></i>{" "}
											Califica este producto
										</button>
										<div>
											<span style={{ color: "green" }}>
												{
													this.state
														.reviewApiSuccessResponse
												}
											</span>
										</div>
									</div>
								) : (
									<></>
								)}
								{/* <div dangerouslySetInnerHTML={{ __html: this.htmlDecode(product.specification_script) }} /> */}
								{/* <div dangerouslySetInnerHTML={{ __html: this.htmlDecode(product.specification_iframe) }} /> */}

								{this.state.productMarca == "samsung" ||
								this.state.productMarca == "lg" ? (
									<iframe
										src={`https://syndication-sola.com/view/index.php?product=${this.state.productModeloCapital}&country=PA&lang=es&sec=csa&header=no&footer=no&mesrm=yes&vd=yes&slr=yes&aww=yes&crct=yes&hggt=yes&spec=yes&spectab=yes&scrl=no`}
										width="100%"
										height="100%"
										frameBorder="0"
										scrolling="yes"
										title="sdff"
									/>
								) : (
									<></>
								)}
							</div>
						</div>

						<Modal
							show={this.state.showNotifyModal}
							onHide={this.closeNotifyPop}
							animation={true}
						>
							<Modal.Body>
								<div className="">
									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<button
											type="button"
											className="close"
											onClick={this.closeNotifyPop}
										>
											&times;
										</button>
									</div>
									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<form
											className="notify_prod_form"
											id="forgot-password-form"
											onSubmit={
												this.handleOnNotifyProductSubmit
											}
										>
											<div className="col-xs-12">
												<h4
													style={{
														fontSize: "20.5px",
													}}
												>
													Notificar cuando el producto
													esté disponible
												</h4>
												<p>
													Suscríbase a este producto
													para recibir un correo
													electrónico una vez que esté
													disponible
												</p>
											</div>
											<div className="col-xs-12">
												<Form.Group controlId="formGroupCardNumber">
													<Form.Control
														type="email"
														name="notifytoemail"
														value={notifytoemail}
														onChange={
															this
																.handleOnNotifyEmailInputChange
														}
														placeholder="Enter Your Email"
														className={
															"form-control" +
															(notifyProductSubmitted &&
															!notifytoemail
																? " has-error"
																: "")
														}
														maxLength="60"
													/>
													<span
														style={{ color: "red" }}
													>
														{
															this.state.errors[
																"notifytoemail"
															]
														}
													</span>
													<span
														style={{
															color: "green",
														}}
													>
														{
															this.state
																.notifyApiSuccessResponse
														}
													</span>
												</Form.Group>
											</div>

											<div className="col-xs-12 signup-btn-wrap">
												<button
													className="btn"
													type="submit"
												>
													<i
														className={`fa fa-refresh fa-spin ${this.state.notifyProdLoader}`}
														style={{
															fontSize: "17px",
														}}
													></i>{" "}
													Notificarme
												</button>
											</div>
										</form>
									</div>
								</div>
							</Modal.Body>
						</Modal>

						<Modal
							show={this.state.showServiceCenterModal}
							onHide={this.closeServiceCenterPop}
							animation={true}
						>
							<Modal.Body>
								<div className="popupbg">
									<button
										type="button"
										className="close"
										onClick={this.closeServiceCenterPop}
									>
										&times;
									</button>
									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<h2>Centro de Servicio</h2>
										{this.state.productServiceCenter &&
										this.state.productServiceCenter.length >
											0
											? this.state.productServiceCenter.map(
													(servicecenter, index) => (
														<div
															className="card mb-3"
															style={{
																width: "100%",
															}}
														>
															<div
																className="card-body"
																style={{
																	textAlign:
																		"left",
																}}
															>
																<h5
																	className="card-title"
																	style={{
																		color: "#000",
																	}}
																>
																	{
																		servicecenter.center
																	}
																</h5>
																{servicecenter.address && (
																	<p className="card-text">
																		<strong>
																			Address:
																		</strong>{" "}
																		<AddressLink
																			address={
																				servicecenter.address
																			}
																		/>
																	</p>
																)}
																{servicecenter.contact_no.length > 0 && (
																	<p className="card-text">
																		<strong>Contact:</strong>{" "}
																		<div style={{ paddingLeft: "20px", marginTop: "5px" }}>
																			{servicecenter.contact_no.map((contact, index) => (
																				<div key={index} style={{ marginBottom: "5px" }}>
																					<a
																						className="whatsapp"
																						rel="noopener noreferrer"
																						href={`tel:+${contact.std_code}${contact.number}`}
																					>
																						+({contact.std_code}) {contact.number}
																					</a>
																				</div>
																			))}
																		</div>
																	</p>
																)}
																{servicecenter.whatsapp_no && (
																	<p className="card-text">
																		<strong>
																			Whatsapp:
																		</strong>{" "}
																		<a
																			className="whatsapp"
																			rel="noopener noreferrer"
																			href={`https://api.whatsapp.com/send?phone=${servicecenter.whatsapp_no}&text=Hola. Necesito detalles sobre el centro de servicio.`}
																			target="_blank"
																		>
																			{
																				servicecenter.whatsapp_no
																			}
																		</a>
																	</p>
																)}
																{servicecenter.hours && (
																	<p className="card-text">
																		<strong>
																			Hours:
																		</strong>{" "}
																		{
																			servicecenter.hours
																		}
																	</p>
																)}
															</div>
														</div>
													)
											  )
											: "No se encontraron informacion del centro de servicio"}
									</div>
								</div>
							</Modal.Body>
						</Modal>

						<Modal
							show={this.state.showLoginModal}
							onHide={this.closeLoginPop}
							animation={true}
						>
							<Modal.Body>
								<div className="popupbg">
									<button
										type="button"
										className="close"
										onClick={this.closeLoginPop}
									>
										&times;
									</button>
									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 right-form-area">
										<div className="right-form-logo mobile-logo-login">
											<img
												src={`${process.env.PUBLIC_URL}/images/popup-logo.png`}
												title="logo"
												alt="logo"
											/>
										</div>
										<h2> Iniciar sesion</h2>
										<h3>
											{" "}
											Obtenga acceso sus cuentas y pedidos
										</h3>
										<div className="wraparea">
											<div
												className={`form-wrap ${
													this.props.loginErrorMessage
														? ""
														: "hidden"
												} `}
											>
												<label style={apiError}>
													{
														this.props
															.loginErrorMessage
													}
												</label>
											</div>
											<div
												className={`form-wrap ${
													this.props
														.loginSuccessMessage
														? ""
														: "hidden"
												} `}
											>
												<label style={apiSuccess}>
													{
														this.props
															.loginSuccessMessage
													}
												</label>
											</div>
											<form
												className={`login_form ${this.state.loginFormShowHide}`}
												id="login-form"
												onSubmit={
													this
														.handleOnProductLoginSubmit
												}
											>
												<div className="form-wrap">
													<div className="input-wrap">
														<span className="login-icon">
															<img
																src={`${process.env.PUBLIC_URL}/images/new-images/icon/mail-icon.svg`}
																alt="Mail Icon"
																rel="noopener noreferrer"
															/>
														</span>
														<input
															className={
																"input-text" +
																(loginSubmitted &&
																!username
																	? " has-error"
																	: "")
															}
															type="email"
															name="username"
															value={username}
															onChange={
																this
																	.handleOnLoginInputChange
															}
															placeholder="Introduce tu correo electrónico"
															maxLength="70"
														/>
													</div>
													<span
														style={{ color: "red" }}
													>
														{
															this.state.errors[
																"username"
															]
														}
													</span>
												</div>
												<div className="form-wrap">
													<div className="input-wrap">
														<span className="login-icon">
															<img
																src={`${process.env.PUBLIC_URL}/images/new-images/icon/password-icon.svg`}
																alt="Password Icon"
																rel="noopener noreferrer"
															/>
														</span>
														<input
															className={
																"input-text" +
																(loginSubmitted &&
																!password
																	? " has-error"
																	: "")
															}
															type="password"
															name="password"
															value={password}
															onChange={
																this
																	.handleOnLoginInputChange
															}
															placeholder="Ingresa tu contraseña"
															maxLength="50"
														/>
													</div>
													<span
														style={{ color: "red" }}
													>
														{
															this.state.errors[
																"password"
															]
														}
													</span>
												</div>
												<Link
													to="#"
													id="forgots"
													className="no-account"
													onClick={(e) =>
														this.handleOnFormChange(
															"forgotpassword"
														)
													}
												>
													¿Olvidaste tu contraseña?{" "}
												</Link>
												<div className="signup-btn-wrap">
													<button
														className="btn"
														value="Login"
														id="logins"
														type="submit"
														disabled={
															this.props
																.loginFormButtonDisabled
														}
													>
														<i
															className={`fa fa-refresh fa-spin ${this.props.loginLoader}`}
															style={{
																fontSize:
																	"17px",
															}}
														></i>{" "}
														Iniciar sesion
													</button>
												</div>
												<div className="reg-btn-wrap">
													<button
														className="btn margin-auto-top"
														type="button"
														onClick={(e) =>
															this.handleOnFormChange(
																"registration"
															)
														}
													>
														Regístrate
													</button>
												</div>
											</form>

											<form
												className={`registration_form ${this.state.registrationFormShowHide}`}
												id="registration-form"
												onSubmit={
													this
														.handleOnRegistrationSubmit
												}
											>
												<div className="form-wrap">
													<label>Primer Nombre</label>
													<input
														className={
															"input-text" +
															(registrationSubmitted &&
															!firstname
																? " has-error"
																: "")
														}
														name="firstname"
														value={firstname}
														onChange={
															this
																.handleOnLoginInputChange
														}
														placeholder="Enter Your First Name"
														maxLength="70"
													/>
													<span
														style={{ color: "red" }}
													>
														{
															this.state.errors[
																"firstname"
															]
														}
													</span>
												</div>
												<div className="form-wrap">
													<label>Apellido</label>
													<input
														className={
															"input-text" +
															(registrationSubmitted &&
															!lastname
																? " has-error"
																: "")
														}
														name="lastname"
														value={lastname}
														onChange={
															this
																.handleOnRegistrationInputChange
														}
														placeholder="Enter Your Last Name"
														maxLength="50"
													/>
													<span
														style={{ color: "red" }}
													>
														{
															this.state.errors[
																"lastname"
															]
														}
													</span>
												</div>
												<div className="form-wrap">
													<label>
														Correo electrónico
													</label>
													<input
														className={
															"input-text" +
															(registrationSubmitted &&
															!email
																? " has-error"
																: "")
														}
														type="email"
														name="email"
														value={email}
														onChange={
															this
																.handleOnRegistrationInputChange
														}
														placeholder="Enter Your Username"
														maxLength="50"
													/>
													<span
														style={{ color: "red" }}
													>
														{
															this.state.errors[
																"email"
															]
														}
													</span>
												</div>
												<div className="form-wrap">
													<label>Teléfono</label>
													<IntlTelInput
														containerClassName="intl-tel-input"
														inputClassName={
															"input-text" +
															(registrationSubmitted &&
															!telephone
																? " has-error"
																: "")
														}
														fieldName="telephone"
														value={telephone}
														onPhoneNumberChange={(
															...args
														) => {
															this.handleOnRegistrationTelInputChange(
																...args
															);
														}}
														placeholder="Enter Your Telephone Number"
														maxLength="50"
														style={{
															width: "100%",
														}}
														separateDialCode
														format={true}
													/>
													{/* <input className={'input-text' + (registrationSubmitted && !telephone ? ' has-error' : '')} name="telephone" value={telephone} onChange={this.handleOnRegistrationInputChange} placeholder="Enter Your Telephone Number" maxLength="50"/> */}
													<span
														style={{ color: "red" }}
													>
														{
															this.state.errors[
																"telephone"
															]
														}
													</span>
												</div>
												<div className="form-wrap">
													<label>Contraseña</label>
													<input
														className={
															"input-text" +
															(registrationSubmitted &&
															!regpassword
																? " has-error"
																: "")
														}
														type="password"
														name="regpassword"
														value={regpassword}
														onChange={
															this
																.handleOnRegistrationInputChange
														}
														placeholder="Enter Your Password"
														maxLength="50"
													/>
													<span
														style={{ color: "red" }}
													>
														{
															this.state.errors[
																"regpassword"
															]
														}
													</span>
												</div>
												<Link
													to="#"
													id="forgots"
													className="no-account"
													onClick={(e) =>
														this.handleOnFormChange(
															"login"
														)
													}
												>
													Usuario existente? Iniciar
													sesion{" "}
												</Link>
												<div className="reg-btn-wrap">
													<button
														className="btn"
														value="Registration"
														id="reg"
														type="submit"
														disabled={
															this.state
																.loginFormButtonDisabled
														}
													>
														<i
															className={`fa fa-refresh fa-spin ${this.state.registrationLoader}`}
															style={{
																fontSize:
																	"17px",
															}}
														></i>{" "}
														Regístrate
													</button>
												</div>
												<div className="signup-btn-wrap">
													<button
														className="btn"
														type="button"
														onClick={(e) =>
															this.handleOnFormChange(
																"login"
															)
														}
													>
														Iniciar sesion
													</button>
												</div>
											</form>

											<form
												className={`forgot_pass_form ${this.state.forgotPasswordShowHide}`}
												id="forgot-password-form"
												onSubmit={
													this
														.handleOnForgotPasswordSubmit
												}
											>
												<div className="form-wrap">
													<label>
														Correo electrónico
													</label>
													<input
														className={
															"input-text" +
															(forgotPasswordSubmitted &&
															!forgotpassemail
																? " has-error"
																: "")
														}
														type="email"
														name="forgotpassemail"
														value={forgotpassemail}
														onChange={
															this
																.handleOnRegistrationInputChange
														}
														placeholder="Enter Your Email"
														maxLength="50"
													/>
													<span
														style={{ color: "red" }}
													>
														{
															this.state.errors[
																"forgotpassemail"
															]
														}
													</span>
													<span
														style={{
															color: "green",
														}}
													>
														{
															this.state
																.forgotPasswordApiSuccessResponse
														}
													</span>
													<span
														style={{ color: "red" }}
													>
														{
															this.state
																.forgotPasswordApiErrorResponse
														}
													</span>
												</div>
												<Link
													to="#"
													id="forgots"
													className="no-account"
													onClick={(e) =>
														this.handleOnFormChange(
															"login"
														)
													}
												>
													{" "}
													Atrás para iniciar sesión?{" "}
												</Link>
												<div className="signup-btn-wrap">
													<button
														className="btn"
														value="Forgot Password"
														type="submit"
														disabled={
															this.state
																.loginFormButtonDisabled
														}
													>
														<i
															className={`fa fa-refresh fa-spin ${this.state.forgotPassLoader}`}
															style={{
																fontSize:
																	"17px",
															}}
														></i>{" "}
														Enviar
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</Modal.Body>
						</Modal>

						<Modal
							show={this.state.showUpdateCartMsgModal}
							onHide={this.cartUpdatePopupResClose}
							animation={true}
						>
							<Modal.Header closeButton>
								<Modal.Title>Oops!</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<p style={{ textAlign: "center" }}>
									{this.state.cartErrorMessage}
								</p>
							</Modal.Body>
						</Modal>

						<Modal
							show={this.state.showCartReturnModal}
							onHide={this.closeCartReturnPop}
							animation={true}
						>
							<Modal.Header>
								<button
									type="button"
									className="close"
									onClick={this.closeCartReturnPop}
								>
									&times;
								</button>
								<Modal.Title>
									<i
										className="fa fa-check"
										aria-hidden="true"
									></i>{" "}
									El producto ha sido agregado al carrito.
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<p
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										flexWrap: "wrap",
										gap: "20px",
										marginTop: "17px",
										textAlign: "center",
									}}
								>
									<Link
										to="/cart"
										className="btn btn-primary btn-lg"
									>
										&nbsp;&nbsp;Proceder a Pagar{" "}
										<i
											className="fa fa-arrow-right"
											aria-hidden="true"
										></i>
										&nbsp;&nbsp;
									</Link>
									<button
										className="btn btn-success btn-lg"
										onClick={this.closeCartReturnPop}
									>
										&nbsp;&nbsp;
										<i
											className="fa fa-shopping-basket"
											aria-hidden="true"
										></i>{" "}
										Seguir Comprando&nbsp;&nbsp;
									</button>
								</p>
							</Modal.Body>
						</Modal>
					</>
				);
			} else {
				return (
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div className="details-container">
							<h2 style={{ textAlign: "center" }}>
								{this.state.productDetailsErrorMsg}
							</h2>
						</div>
					</div>
				);
			}
		} else {
			return (
				<>
					<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
						<Skeleton height={550} width={550} />
					</div>
					<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
						<div className="details-container">
							<Skeleton
								height={30}
								width={370}
								style={{ marginBottom: "7px" }}
							/>
							<Skeleton
								height={25}
								width={450}
								style={{ marginBottom: "7px" }}
							/>
							<Skeleton
								width={550}
								style={{ marginBottom: "25px" }}
							/>
							<Skeleton
								height={35}
								width={120}
								style={{
									display: "block",
									marginBottom: "20px",
								}}
							/>
							<div
								style={{
									display: "block",
									marginBottom: "10px",
								}}
							>
								<Skeleton
									width={23}
									height={25}
									style={{
										position: "relative",
										top: "10px",
										marginRight: "10px",
									}}
								/>
								<Skeleton height={43} width={55} />
							</div>
							<Skeleton
								height={45}
								width={170}
								style={{ marginRight: "15px" }}
							/>
							<Skeleton height={48} width={220} />

							<div style={{ display: "block" }}>
								<Skeleton
									height={45}
									width={50}
									style={{
										marginRight: "10px",
										marginTop: "20px",
									}}
								/>
								<Skeleton
									height={45}
									width={50}
									style={{ marginRight: "10px" }}
								/>
								<Skeleton height={45} width={50} />
							</div>
						</div>
					</div>
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<h3 style={{ marginTop: "20px" }}>
							<Skeleton width={500} />
						</h3>
						<h2>
							<Skeleton width={600} />
						</h2>
						<Skeleton count={10} />
					</div>
				</>
			);
		}
	}
}

function mapStateToProps(state) {
	localStorage.setItem("cartData", JSON.stringify(state.cart.cartData));
	localStorage.setItem(
		"quotationData",
		JSON.stringify(state.quotation.quotationData)
	);
	localStorage.setItem(
		"cartAttributesData",
		JSON.stringify(state.cart.cartAttributesData)
	);
	return {
		isSignedUp: state.user.isSignedUp,
		cartData: state.cart.cartData,
		isLoggedIn: state.user.isLoggedIn,
		isError: state.user.isError,
		loginErrorMessage: state.user.loginErrorMessage,
		loginSuccessMessage: state.user.loginSuccessMessage,
		loginLoader: state.user.loginLoader,
		loginFormButtonDisabled: state.user.loginFormButtonDisabled,
		isSignupError: state.user.isSignupError,
		signupErrorMessage: state.user.signupErrorMessage,
		isCartAddError: state.cart.isCartAddError,
		isQuotationAdded: state.quotation.isQuotationAdded,
		cartErrorMessage: state.cart.cartErrorMessage,
		userEmail:
			typeof state.user.user === "undefined" ? "" : state.user.user.email,
		customerId:
			typeof state.user.user === "undefined"
				? ""
				: state.user.user.customer_id,
	};
}

const actionCreators = {
	addToCart: addToCart2,
	addAttributeToCart,
	logIn: login2,
	addToQuotation,
};

const ProductDetailsListArea = connect(
	mapStateToProps,
	actionCreators
)(ProductDetailsListAreaComp);

export default withRouter(ProductDetailsListArea);
