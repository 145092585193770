import React, { Component } from "react";
import { Link } from "react-router-dom";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import ProductSearchAutocomplete from "./ProductSearchAutocomplete";
import DesktopHeaderMenuSection from "./DesktopHeaderMenuSection";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { login2, logOut, signup } from "../../Services/Actions/user.actions";
import { allowGuestCheckout } from "../../Services/Actions/checkout.actions";
import Form from "react-bootstrap/Form";
import API_BASE_URL from "../../config/api";

class DesktopHeaderSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			customername: "",
			firstname: "",
			lastname: "",
			email: "",
			telephone: "",
			fulltelephonenumber: "",
			regpassword: "",
			forgotpassemail: "",
			errors: {},
			forgotPassLoader: "hidden",
			registrationLoader: "hidden",
			showModal: false,
			submitted: false,
			apiErrorMessage: "",
			apiSuccessMessage: "",
			loginSubmitted: false,
			registrationSubmitted: false,
			forgotPasswordSubmitted: false,
			forgotPasswordApiSuccessResponse: "",
			forgotPasswordApiErrorResponse: "",
			loginCheck: false,
			loginFormShowHide: "",
			registrationFormShowHide: "hidden",
			forgotPasswordShowHide: "hidden",
		};

		this.handleOnLoginInputChange =
			this.handleOnLoginInputChange.bind(this);
		this.handleOnRegistrationInputChange =
			this.handleOnRegistrationInputChange.bind(this);
		this.handleOnLoginSubmit = this.handleOnLoginSubmit.bind(this);
		this.handleOnRegistrationSubmit =
			this.handleOnRegistrationSubmit.bind(this);
		this.handleOnForgotPasswordSubmit =
			this.handleOnForgotPasswordSubmit.bind(this);
		this.logOut = this.logOut.bind(this);
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);

		this.handleOnRegistrationTelInputChange =
			this.handleOnRegistrationTelInputChange.bind(this);
	}

	handleOnGuestCheckoutRedirect() {
		this.props.allowGuestCheckout();
	}

	handleOnRegistrationTelInputChange(
		isValid,
		newNumber,
		countryData,
		fullNumber,
		isExtension
	) {
		this.setState({ telephone: newNumber });
		this.setState({ fulltelephonenumber: fullNumber });
	}

	handleOnLoginInputChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	handleOnRegistrationInputChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	handleLoginFormValidation() {
		const { email, password } = this.state;
		const errors = {};
		let loginFormIsValid = true;

		if (!email) {
			loginFormIsValid = false;
			errors.email = "Email can not be empty";
		} else if (
			!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)
		) {
			loginFormIsValid = false;
			errors.email = "Email is not valid";
		}

		if (!password) {
			loginFormIsValid = false;
			errors.password = "Password can not be empty";
		}

		this.setState({ errors });
		return loginFormIsValid;
	}

	handleOnLoginSubmit(e) {
		e.preventDefault();
		this.setState({ loginSubmitted: true });

		if (this.handleLoginFormValidation()) {
			//this.setState({ loginLoader: "", loginFormButtonDisabled: true });
			const email = this.state.email;
			const password = this.state.password;
			const cartItems = this.props.cartItems;

			this.props.logIn(email, password, cartItems);
			// setTimeout(()=>
			//     this.setState({
			//         loginCheck: this.props.isLoggedIn
			//     },() => {
			//         if(this.props.isError){
			//             this.setState({apiErrorMessage: this.props.errorMessage, loginFormButtonDisabled: false, loginLoader: "hidden"})
			//         }
			//     }),
			// 3000);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// only update chart if the data has changed
		if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
			const isLoggedIn = this.props.isLoggedIn;
			if (isLoggedIn) {
				this.setState({
					username: "",
					password: "",
				});

				setTimeout(() => this.close(), 2000);
			}
		}
	}

	handleRegistrationFormValidation() {
		//let firstname = this.state.firstname;
		//let lastname = this.state.lastname;
		let customername = this.state.customername;
		let email = this.state.email;
		let telephone = this.state.telephone;
		let regpassword = this.state.regpassword;
		let errors = {};
		let registrationFormIsValid = true;

		/* if (!firstname) {
			registrationFormIsValid = false;
			errors["firstname"] = "Please enter your first name";
		}

		if (!lastname) {
			registrationFormIsValid = false;
			errors["lastname"] = "Please enter your last name";
		} */
		if (!customername) {
			registrationFormIsValid = false;
			errors["customername"] = "Please enter your name";
		}

		if (!telephone) {
			registrationFormIsValid = false;
			errors["telephone"] = "Please enter your phone number.";
		} else {
			if (telephone.length < 8 || telephone.length > 15) {
				registrationFormIsValid = false;
				errors["telephone"] = "Please enter your valid phone number.";
			}
		}

		if (typeof email !== "undefined") {
			let lastAtPos = email.lastIndexOf("@");
			let lastDotPos = email.lastIndexOf(".");

			if (
				!(
					lastAtPos < lastDotPos &&
					lastAtPos > 0 &&
					email.indexOf("@@") === -1 &&
					lastDotPos > 2 &&
					email.length - lastDotPos > 2
				)
			) {
				registrationFormIsValid = false;
				errors["email"] = "Email id is not valid";
			}
		} else {
			registrationFormIsValid = false;
			errors["email"] = "Please enter your username.";
		}

		if (!regpassword) {
			registrationFormIsValid = false;
			errors["regpassword"] = "Password can not be empty";
		} else {
			if (regpassword.length < 3) {
				registrationFormIsValid = false;
				errors["regpassword"] =
					"Please enter minimum 4 characters password.";
			}
		}

		this.setState({ errors: errors });
		return registrationFormIsValid;
	}

	handleOnRegistrationSubmit(e) {
		e.preventDefault();
		this.setState({
			registrationSubmitted: true,
			apiSuccessMessage: "",
			apiErrorMessage: "",
		});

		if (this.handleRegistrationFormValidation()) {
			this.setState({
				registrationLoader: "",
				loginFormButtonDisabled: true,
			});
			//const first_name = this.state.firstname;
			//const last_name = this.state.lastname;
			const customername = this.state.customername;
			const email = this.state.email;
			const password = this.state.regpassword;
			//const telephone = this.state.telephone;
			const fulltelephonenumber = this.state.fulltelephonenumber;
			const cartItems = this.props.cartItems;

			this.props.signup(
				customername,
				email,
				password,
				fulltelephonenumber,
				cartItems
			);

			setTimeout(
				() =>
					this.setState(
						{
							loginCheck: this.props.isLoggedIn,
						},
						() => {
							if (this.state.loginCheck) {
								this.setState({
									apiSuccessMessage:
										"Your account has been successfully registered on our E-vision store.",
									loginFormButtonDisabled: false,
									registrationLoader: "hidden",
								});
								setTimeout(
									() => this.close(),
									this.setState({ apiSuccessMessage: "" }),
									3000
								);
							}
							if (this.props.isSignupError) {
								this.setState({
									apiErrorMessage:
										this.props.signupErrorMessage,
									loginFormButtonDisabled: false,
									registrationLoader: "hidden",
								});
								setTimeout(
									() =>
										this.setState({ apiErrorMessage: "" }),
									5000
								);
							}
						}
					),
				2000
			);
		}
	}

	handleForgotPassFormValidation() {
		let forgotpassemail = this.state.forgotpassemail;
		let errors = {};
		let forgotPasswordFormIsValid = true;

		if (typeof forgotpassemail !== "undefined") {
			let lastAtPos = forgotpassemail.lastIndexOf("@");
			let lastDotPos = forgotpassemail.lastIndexOf(".");

			if (
				!(
					lastAtPos < lastDotPos &&
					lastAtPos > 0 &&
					forgotpassemail.indexOf("@@") === -1 &&
					lastDotPos > 2 &&
					forgotpassemail.length - lastDotPos > 2
				)
			) {
				forgotPasswordFormIsValid = false;
				errors["forgotpassemail"] =
					"Your registered email id is not valid";
			}
		} else {
			forgotPasswordFormIsValid = false;
			errors["forgotpassemail"] = "Please enter your registered email id";
		}

		this.setState({ errors: errors });
		return forgotPasswordFormIsValid;
	}

	handleOnForgotPasswordSubmit(e) {
		e.preventDefault();
		this.setState({ forgotPasswordSubmitted: true });

		if (this.handleForgotPassFormValidation()) {
			this.setState({
				forgotPassLoader: "",
				loginFormButtonDisabled: true,
			});
			let forgotpassemail = this.state.forgotpassemail;

			//const apiUrl = "https://www.evisionstore.com/api/user/forgotpassword.php";

			const apiUrl = `${API_BASE_URL}/forgot-password`;

			fetch(apiUrl, {
				method: "POST",
				body: JSON.stringify({
					email: forgotpassemail,
				}),
			})
				.then((res) => res.json())
				.then(
					(result) => {
						if (result.code === 200) {
							this.setState({
								forgotPasswordApiSuccessResponse:
									result.message,
								forgotPassLoader: "hidden",
								loginFormButtonDisabled: false,
							});
						} else {
							this.setState({
								forgotPasswordApiErrorResponse: result.message,
								forgotPassLoader: "hidden",
								loginFormButtonDisabled: false,
							});
						}
					},
					(error) => {
						this.setState({
							error,
							forgotPassLoader: "hidden",
							loginFormButtonDisabled: false,
						});
					}
				);
		}
	}

	logOut() {
		//console.log("Logout: ", this.props);
		this.props.logOut();
	}

	handleOnFormChange(buttonParam) {
		if (buttonParam === "registration") {
			this.setState({
				loginFormShowHide: "hidden",
				registrationFormShowHide: "",
				forgotPasswordShowHide: "hidden",
			});
		} else if (buttonParam === "login") {
			this.setState({
				loginFormShowHide: "",
				registrationFormShowHide: "hidden",
				forgotPasswordShowHide: "hidden",
			});
		} else if (buttonParam === "forgotpassword") {
			this.setState({
				loginFormShowHide: "hidden",
				registrationFormShowHide: "hidden",
				forgotPasswordShowHide: "",
			});
		}
	}

	open() {
		this.setState({ showModal: true });
	}

	close() {
		this.setState({ showModal: false });
	}

	render() {
		const { isLoggedIn, isSignedUp, cartCount } = this.props;
		const {
			username,
			customername,
			password,
			firstname,
			lastname,
			email,
			telephone,
			regpassword,
			loginSubmitted,
			registrationSubmitted,
			forgotPasswordSubmitted,
			forgotpassemail,
			loginCheck,
		} = this.state;

		const apiError = {
			color: "red",
			fontSize: "16px",
			background: "#fff9b0",
			padding: "0px 5px 2px 5px",
			fontWeight: "normal",
			textAlign: "center",
			border: "1px solid red",
			borderRadius: "3px",
		};

		const apiSuccess = {
			color: "#316403",
			fontSize: "16px",
			background: "#cbffb0",
			padding: "0px 5px 2px 5px",
			fontWeight: "normal",
			textAlign: "center",
			border: "1px solid #178603",
			borderRadius: "3px",
		};

		// if(loginCheck){
		//     window.location.reload(false);
		// }

		//console.log(this.props.loginLoader)

		// if (isSignedUp) {
		//     return <Redirect to={{ pathname: "/profile", state: { referer: this.props.location } }} />;
		// }

		const AfterLoggedIn = (
			<li>
				<Link to="/profile">
					<img
						src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-login.svg`}
						alt="Mi Cuenta"
						className="icon-float"
					/>{" "}
					Mi Cuenta
					{/* <i className="fa fa-angle-down" aria-hidden="true" /> */}
				</Link>
				{/* <ul>
                        <li><Link to="/profile">My Profile</Link></li>
                        <li><Link to="#" onClick={() => this.props.logOut()}>Logout</Link></li>
                        <li><Link to="#" onClick={this.logOut}>Logout</Link></li>
                    </ul> */}
			</li>
		);

		const BeforeLoggedIn = (
			<li>
				<Link to="#" onClick={this.open}>
					<img
						src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-login.svg`}
						alt="Iniciar sesión"
						width="17"
						height="18"
						className=""
					/>{" "}
					Acceso
				</Link>
			</li>
		);

		return (
			<header className="desktop">
				<div className="header-top-section">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
								<div className="header-phone">
									<ul style={{ padding: "0px" }}>
										<li>
											<Link to="/sucursales">
												<img
													src={`${process.env.PUBLIC_URL}/images/new-images/icon/branch-icon.svg`}
													alt="Branch Icon"
													width="14"
													height="17"
													className="icon-float"
												/>
												Sucursales
											</Link>
										</li>
										<li>
											<a
												rel="noopener noreferrer"
												target="_blank"
												href="https://api.whatsapp.com/send?phone=50764447679&text=Guarda nuestro número en tus contactos para recibir nuestra newsletter. Y después envía este mensaje para comenzar."
											>
												<img
													src={`${process.env.PUBLIC_URL}/images/new-images/icon/whatsapp-icon.svg`}
													alt="Whatsapp Icon"
													width="17"
													height="17"
													className="icon-float"
												/>
												Whatsapp
											</a>
										</li>
										<li>
											<a href="tel:+507-3021030">
												<img
													src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-phone.svg`}
													alt="Phone"
													width="17"
													height="17"
													className="icon-float"
												/>
												Teléfono
											</a>
										</li>
										<li>
											<Link to="/krediya">
												<img
													src={`${process.env.PUBLIC_URL}/images/krediya/krediyatoplogo.jpg`}
													alt="Krediya Logo"
													className="krediya-icon"
												/>
											</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
								<div className="header-address">
									<ul>
										{/* <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/images/info-icon.png`} alt="" className="icon-float" />Ayuda</Link></li> */}
										{/* <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/images/chat-icon.png`} alt="" className="icon-float" />Chat En Vivo</Link></li> */}
										{/* <li><Link to="/about">Sobre Nosotros</Link></li> */}

										{!isLoggedIn && cartCount > 0 ? (
											<li>
												<Link
													to="/checkout"
													onClick={() =>
														this.handleOnGuestCheckoutRedirect()
													}
												>
													<img
														src={`${process.env.PUBLIC_URL}/images/new-images/icon/continue-guiest.svg`}
														alt="Continue Guiest"
														width="17"
														height="17"
														className="icon-float"
													/>
													Continuar Como Invitado
												</Link>
											</li>
										) : (
											""
										)}

										{isLoggedIn ? (
											<li>
												<Link
													to="#"
													onClick={this.logOut}
												>
													<img
														src={`${process.env.PUBLIC_URL}/images/new-images/icon/logout-header-top.svg`}
														alt="Continue Guiest"
														width="17"
														height="17"
														className="icon-float"
													/>
													Cerrar sesión
												</Link>
											</li>
										) : (
											""
										)}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="header1-area header-two">
					<div className="header-top-area" id="sticker">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-2 col-md-3 col-sm-3 col-xs-6 p-md-0">
									<div className="logo-area">
										<Link to="/">
											<img
												src={`${process.env.PUBLIC_URL}/images/logo.png`}
												alt="logo"
											/>
										</Link>
									</div>
								</div>

								<div className="col-lg-5 col-md-9 col-sm-6 col-xs-12">
									<DesktopHeaderMenuSection />
								</div>

								<div className="col-lg-3 col-md-9 col-sm-3 col-xs-12">
									<ProductSearchAutocomplete />
								</div>

								<div className="col-lg-2 col-md-3 col-sm-4 col-xs-12 p-0">
									<div className="main-menu">
										<ul>
											{/* <li className="offer">
                                                <Link to="#">Vaya Cosa<span>Don't Miss Out!</span> <span>Ends In 18:38:59</span></Link>
                                            </li> */}
											{isLoggedIn
												? AfterLoggedIn
												: BeforeLoggedIn}
											<li>
												<Link to="/cart">
													<div className="cart-wrap-header">
														<div className="cart-counter">
															{cartCount}
														</div>
														<img
															src={`${process.env.PUBLIC_URL}/images/new-images/icon/top-cart.svg`}
															alt="Top Cart"
															className=""
														/>
													</div>{" "}
													Mi Carrito
													{/* <i className="fa fa-angle-down" aria-hidden="true"></i>												   */}
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal
					show={this.state.showModal}
					onHide={this.close}
					animation={true}
					size="xl"
					className="login-modal"
					aria-labelledby="login-modal-sizes-title-lg"
				>
					<Modal.Body>
						<div className="login-modal-wrapper">
							<button
								type="button"
								className="close modal-close"
								onClick={this.close}
							>
								&times;
							</button>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
								<div className="left-login-box">
									<img
										src={`${process.env.PUBLIC_URL}/images/new-images/login-image.png`}
										alt="Login Image"
										className=""
										rel="noopener noreferrer"
									/>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
								<div className="right-form-area right-login-box">
									<div className="right-form-logo right-login-form-logo modal-login-screen">
										<img
											src={`${process.env.PUBLIC_URL}/images/new-images/popup-logo.svg`}
											title="logo"
											alt="logo"
										/>
									</div>
									<h2>Iniciar sesion</h2>
									<p>
										Obtenga acceso a sus cuentas y sus
										pedidos
									</p>
									<div
										className={`form-wrap ${
											this.props.loginErrorMessage
												? ""
												: "hidden"
										} `}
									>
										<label style={apiError}>
											{this.props.loginErrorMessage}
										</label>
									</div>
									<div
										className={`form-wrap ${
											this.props.loginSuccessMessage
												? ""
												: "hidden"
										} `}
									>
										<label style={apiSuccess}>
											{this.props.loginSuccessMessage}
										</label>
									</div>
									<div
										className={`form-wrap ${
											this.state.apiErrorMessage
												? ""
												: "hidden"
										} `}
									>
										<label style={apiError}>
											{this.state.apiErrorMessage}
										</label>
									</div>
									<div
										className={`form-wrap ${
											this.state.apiSuccessMessage
												? ""
												: "hidden"
										} `}
									>
										<label style={apiSuccess}>
											{this.state.apiSuccessMessage}
										</label>
									</div>
									<form
										className={`login_form ${this.state.loginFormShowHide}`}
										id="login-form"
										onSubmit={this.handleOnLoginSubmit}
									>
										<div className="form-wrap login-form-wrap">
											{/* <label>Correo electrónico</label> */}
											<div className="input-wrap">
												<span className="login-icon">
													<img
														src={`${process.env.PUBLIC_URL}/images/new-images/icon/mail-icon.svg`}
														alt="Mail Icon"
														rel="noopener noreferrer"
													/>
												</span>
												<input
													className={
														"input-text" +
														(loginSubmitted &&
														!email
															? " has-error"
															: "")
													}
													type="email"
													name="email"
													value={email}
													onChange={
														this
															.handleOnLoginInputChange
													}
													placeholder="Introduce tu correo electrónico"
													maxLength="70"
												/>
											</div>
											<span style={{ color: "red" }}>
												{this.state.errors["username"]}
											</span>
										</div>
										<div className="form-wrap login-form-wrap">
											{/* <label>Contraseña</label> */}
											<div className="input-wrap">
												<span className="login-icon">
													<img
														src={`${process.env.PUBLIC_URL}/images/new-images/icon/password-icon.svg`}
														alt="Mail Icon"
														rel="noopener noreferrer"
													/>
												</span>
												<input
													className={
														"input-text" +
														(loginSubmitted &&
														!password
															? " has-error"
															: "")
													}
													type="password"
													name="password"
													value={password}
													onChange={
														this
															.handleOnLoginInputChange
													}
													maxLength="50"
													placeholder="Enter Your Password"
												/>
											</div>
											<span style={{ color: "red" }}>
												{this.state.errors["password"]}
											</span>
										</div>
										<div className="forgot-remember">
											<div className="remember-me">
												{["checkbox"].map((type) => (
													<Form.Check // prettier-ignore
														type={type}
														id={`default-${type}`}
													/>
												))}
												Acuérdate de mí
											</div>
											<Link
												to="#"
												id="forgots"
												className="no-account"
												onClick={(e) =>
													this.handleOnFormChange(
														"forgotpassword"
													)
												}
											>
												Olvidó contraseña{" "}
											</Link>
										</div>
										<div className="signup-btn-wrap">
											<button
												className="btn"
												value="Login"
												id="logins"
												type="submit"
												disabled={
													this.props
														.loginFormButtonDisabled
												}
											>
												<i
													className={`fa fa-refresh fa-spin ${this.props.loginLoader}`}
													style={{ fontSize: "17px" }}
												></i>{" "}
												Inicia sesión ahora
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
												>
													<path
														d="M1 8H15"
														stroke="white"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M8 1L15 8L8 15"
														stroke="white"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										</div>
										<div className="reg-btn-wrap">
											<p>No tienes una cuenta. </p>
											<button
												className="btn"
												type="button"
												onClick={(e) =>
													this.handleOnFormChange(
														"registration"
													)
												}
											>
												Regístrate ahora!
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
												>
													<path
														d="M1 8H15"
														stroke="white"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M8 1L15 8L8 15"
														stroke="white"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										</div>
									</form>

									<form
										className={`registration_form ${this.state.registrationFormShowHide}`}
										id="registration-form"
										onSubmit={
											this.handleOnRegistrationSubmit
										}
									>
										<div className="form-wrap">
											<div className="input-wrap">
												<span className="login-icon">
													<img
														src={`${process.env.PUBLIC_URL}/images/new-images/icon/name-icon.svg`}
														alt="Mail Icon"
														rel="noopener noreferrer"
													/>
												</span>
												<input
													className={
														"input-text" +
														(registrationSubmitted &&
														!customername
															? " has-error"
															: "")
													}
													name="customername"
													value={customername}
													onChange={
														this
															.handleOnLoginInputChange
													}
													placeholder="Nombre"
													maxLength="70"
												/>
											</div>
											<span style={{ color: "red" }}>
												{
													this.state.errors[
														"customername"
													]
												}
											</span>
										</div>
										{/* <div className="form-wrap">
											<div className="input-wrap">
												<span className="login-icon">
													<img
														src={`${process.env.PUBLIC_URL}/images/new-images/icon/name-icon.svg`}
														alt="Mail Icon"
														rel="noopener noreferrer"
													/>
												</span>
												<input
													className={
														"input-text" +
														(registrationSubmitted &&
														!lastname
															? " has-error"
															: "")
													}
													name="lastname"
													value={lastname}
													onChange={
														this
															.handleOnRegistrationInputChange
													}
													placeholder="Enter Your Last Name"
													maxLength="50"
												/>
											</div>
											<span style={{ color: "red" }}>
												{this.state.errors["lastname"]}
											</span>
										</div> */}
										<div className="form-wrap">
											<div className="input-wrap">
												<span className="login-icon">
													<img
														src={`${process.env.PUBLIC_URL}/images/new-images/icon/mail-icon.svg`}
														alt="Mail Icon"
														rel="noopener noreferrer"
													/>
												</span>
												<input
													className={
														"input-text" +
														(registrationSubmitted &&
														!email
															? " has-error"
															: "")
													}
													type="email"
													name="email"
													value={email}
													onChange={
														this
															.handleOnRegistrationInputChange
													}
													placeholder="Introduce tu correo electrónico"
													maxLength="50"
												/>
											</div>
											<span style={{ color: "red" }}>
												{this.state.errors["email"]}
											</span>
										</div>
										<div className="form-wrap">
											<div className="input-wrap">
												<span className="login-icon">
													<img
														src={`${process.env.PUBLIC_URL}/images/new-images/icon/phone-icon.svg`}
														alt="Mail Icon"
														rel="noopener noreferrer"
													/>
												</span>
												{/* <input className={'input-text' + (registrationSubmitted && !telephone ? ' has-error' : '')} name="telephone" value={telephone} onChange={this.handleOnRegistrationInputChange} placeholder="Enter Your Telephone Number" maxLength="50"/> */}
												<IntlTelInput
													containerClassName="intl-tel-input"
													inputClassName={
														"input-text" +
														(registrationSubmitted &&
														!telephone
															? " has-error"
															: "")
													}
													fieldName="telephone"
													value={telephone}
													onPhoneNumberChange={(
														...args
													) => {
														this.handleOnRegistrationTelInputChange(
															...args
														);
													}}
													placeholder="Enter Your Telephone Number"
													maxLength="50"
													style={{ width: "100%" }}
													separateDialCode
													format={true}
													defaultCountry="pa"
												/>
											</div>
											<span style={{ color: "red" }}>
												{this.state.errors["telephone"]}
											</span>
										</div>
										<div className="form-wrap">
											<div className="input-wrap">
												<span className="login-icon">
													<img
														src={`${process.env.PUBLIC_URL}/images/new-images/icon/password-icon.svg`}
														alt="Mail Icon"
														rel="noopener noreferrer"
													/>
												</span>
												<input
													className={
														"input-text" +
														(registrationSubmitted &&
														!regpassword
															? " has-error"
															: "")
													}
													type="password"
													name="regpassword"
													value={regpassword}
													onChange={
														this
															.handleOnRegistrationInputChange
													}
													placeholder="Enter Your Password"
													maxLength="50"
												/>
											</div>
											<span style={{ color: "red" }}>
												{
													this.state.errors[
														"regpassword"
													]
												}
											</span>
										</div>
										<div className="accept-condition">
											{["checkbox"].map((type) => (
												<Form.Check // prettier-ignore
													type={type}
													id={`default-${type}`}
												/>
											))}
											he aceptado el{" "}
											<a href="#">
												¡Términos y Condiciones!
											</a>
										</div>
										{/* <Link to="#" id="forgots" className="no-account" onClick={(e) => this.handleOnFormChange("login")}>
                      Usuario existente? Iniciar sesion{" "}
                    </Link> */}
										<div className="reg-btn-wrap reg-signup-btn">
											<button
												className="btn"
												value="Registration"
												id="reg"
												type="submit"
												disabled={
													this.state
														.loginFormButtonDisabled
												}
											>
												<i
													className={`fa fa-refresh fa-spin ${this.state.registrationLoader}`}
													style={{ fontSize: "17px" }}
												></i>{" "}
												Regístrate
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
												>
													<path
														d="M1 8H15"
														stroke="white"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M8 1L15 8L8 15"
														stroke="white"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										</div>
										<div className="signup-btn-wrap reg-signin-btn">
											<p>Ya tienes una cuenta.</p>
											<button
												className="btn"
												type="button"
												onClick={(e) =>
													this.handleOnFormChange(
														"login"
													)
												}
											>
												Inicia sesión ahora!
											</button>
										</div>
									</form>

									<form
										className={`forgot_pass_form ${this.state.forgotPasswordShowHide}`}
										id="forgot-password-form"
										onSubmit={
											this.handleOnForgotPasswordSubmit
										}
									>
										<div className="form-wrap">
											{/* <label>Correo electrónico</label> */}
											<div className="input-wrap">
												<span className="login-icon">
													<img
														src={`${process.env.PUBLIC_URL}/images/new-images/icon/mail-icon.svg`}
														alt="Mail Icon"
														rel="noopener noreferrer"
													/>
												</span>
												<input
													className={
														"input-text" +
														(forgotPasswordSubmitted &&
														!forgotpassemail
															? " has-error"
															: "")
													}
													type="email"
													name="forgotpassemail"
													value={forgotpassemail}
													onChange={
														this
															.handleOnRegistrationInputChange
													}
													placeholder="Introduce tu correo electrónico"
													maxLength="50"
												/>
											</div>
											<span style={{ color: "red" }}>
												{
													this.state.errors[
														"forgotpassemail"
													]
												}
											</span>
											<span style={{ color: "green" }}>
												{
													this.state
														.forgotPasswordApiSuccessResponse
												}
											</span>
											<span style={{ color: "red" }}>
												{
													this.state
														.forgotPasswordApiErrorResponse
												}
											</span>
										</div>

										<div className="signup-btn-wrap">
											<button
												className="btn"
												value="Forgot Password"
												type="submit"
												disabled={
													this.state
														.loginFormButtonDisabled
												}
											>
												<i
													className={`fa fa-refresh fa-spin ${this.state.forgotPassLoader}`}
													style={{ fontSize: "17px" }}
												></i>{" "}
												Enviar
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
												>
													<path
														d="M1 8H15"
														stroke="white"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M8 1L15 8L8 15"
														stroke="white"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										</div>
										<div className="text-center">
											<Link
												to="#"
												id="forgots"
												className="no-account"
												onClick={(e) =>
													this.handleOnFormChange(
														"login"
													)
												}
											>
												{" "}
												Atrás para iniciar sesión?{" "}
											</Link>
										</div>
									</form>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</header>
		);
	}
}

function mapStateToProps(state) {
	localStorage.setItem("cartData", JSON.stringify(state.cart.cartData));
	localStorage.setItem(
		"isAllowGuestCheckout",
		state.checkout.isAllowGuestCheckout
	);
	return {
		isSignedUp: state.user.isSignedUp,
		isLoggedIn: state.user.isLoggedIn,
		cartCount: state.cart.cartCount,
		cartItems: state.cart.cartData,
		isError: state.user.isError,
		loginErrorMessage: state.user.loginErrorMessage,
		loginSuccessMessage: state.user.loginSuccessMessage,
		loginLoader: state.user.loginLoader,
		loginFormButtonDisabled: state.user.loginFormButtonDisabled,
		isSignupError: state.user.isSignupError,
		signupErrorMessage: state.user.signupErrorMessage,
		//return isLoggedIn;
	};
}

const MapDispachToProps = (dispatch) => ({
	logIn: login2,
	signup: signup,
	//logOut: () => dispatch(logOut()),
});

const actionCreators = {
	logIn: login2,
	signup: signup,
	logOut: logOut,
	allowGuestCheckout: allowGuestCheckout,
};

const DesktopHeaderTopComp = connect(
	mapStateToProps,
	actionCreators
	//MapDispachToProps
)(DesktopHeaderSection);

export default DesktopHeaderTopComp;
